import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Editor } from 'slate-react';
import { Portal } from 'react-portal';
import OutsideClickHandler from 'react-outside-click-handler';
import RenderEditor from '../helpers/RenderEditor';
// import OutsideClickListener from '../../../OutsideClickListener';
import { a11yHandleEnterKeyPress } from '../../../../utils/a11y';

const MenuButton = styled('span')`
  cursor: pointer;
  color: ${props => {
    if (props.reversed) {
      return props.active ? '#f0003f' : '#aaa';
    }
    return props.active ? 'black' : '#ccc';
  }};
`;

const MenuIcon = styled(({ className, ...rest }) => (
  <span className={`material-icons ${className}`} {...rest} />
))`
  font-size: 24px;
  vertical-align: text-bottom;
`;

const Menu = styled('div')`
  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 15px;
  }
`;

const StyledMenu = styled(Menu)`
  padding: 8px 16px 6px;
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: #222;
  border-radius: 4px;
  transition: opacity 0.75s;
`;

const MarkButton = ({ editor, type, icon }) => {
  const { value } = editor;
  const isActive = value.activeMarks.some(mark => mark.type === type);
  return (
    <MenuButton
      reversed
      active={isActive}
      onMouseDown={event => {
        event.preventDefault();
        editor.toggleMark(type);
      }}
    >
      <MenuIcon>{icon}</MenuIcon>
    </MenuButton>
  );
};

MarkButton.propTypes = {
  editor: PropTypes.instanceOf(Editor),
  type: PropTypes.string,
  icon: PropTypes.string,
};

const LinkButton = ({ editor, icon, onMouseDown }) => {
  const { value } = editor;

  return (
    <MenuButton
      reversed
      active={editor.isLinkActive(value)}
      onMouseDown={onMouseDown}
    >
      <MenuIcon>{icon}</MenuIcon>
    </MenuButton>
  );
};

LinkButton.propTypes = {
  editor: PropTypes.instanceOf(Editor),
  icon: PropTypes.string,
  onMouseDown: PropTypes.func,
};

const StyledLinkInput = styled('input')`
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: -10px;
  width: 95%;
  padding: 2px 10px 6px 10px;
  margin-left: -16px;
  color: #fff;
  border: none;
  outline: 0;
  font-size: 16px;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: 'Lato', san-sans-serif;
  letter-spacing: 0;
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
`;

const StyledCancelLinkButton = styled('span')`
  float: right;
  font-size: 18px;
  color: #fff;
  margin-top: 2px;
  cursor: pointer;
`;

const HoverMenu = React.forwardRef(({ editor }, ref) => {
  const [showUrlInput, setShowUrlInput] = useState(false);
  const [urlInputValue, setUrlInputValue] = useState('');
  const inputEl = useRef(null);
  const { value } = editor;

  useEffect(() => {
    const menu = ref.current;
    if (!menu) return;

    const { fragment, selection } = value;

    if (!showUrlInput) {
      if (
        selection.isBlurred ||
        selection.isCollapsed ||
        fragment.text === ''
      ) {
        menu.removeAttribute('style');
        return;
      }

      const native = window.getSelection();
      const range = native.getRangeAt(0);
      const rect = range.getBoundingClientRect();
      menu.style.opacity = 1;
      menu.style.top = `${rect.top + window.pageYOffset - menu.offsetHeight}px`;
      menu.style.left = `${rect.left +
        window.pageXOffset -
        menu.offsetWidth / 2 +
        rect.width / 2}px`;
    }
  });

  useLayoutEffect(() => {
    if (showUrlInput) {
      const input = inputEl.current;
      if (input) {
        input.focus();
      }
    }
  }, [showUrlInput]);

  const hideLinkInput = (e = null) => {
    if (e !== null) {
      e.preventDefault();
      e.stopPropagation();
    }
    setShowUrlInput(false);
    setUrlInputValue('');
    editor.focus();
  };

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      editor.wrapLink(urlInputValue);
      hideLinkInput();
    } else if (e.keyCode === 27) {
      hideLinkInput();
    }
  };

  const onChange = e => {
    setUrlInputValue(e.target.value);
  };

  const handleLinkInputButton = event => {
    event.preventDefault();
    event.stopPropagation();

    const hasLinks = editor.isLinkActive(value);
    if (hasLinks) {
      editor.unwrapLink();
    } else if (value.selection.isExpanded) {
      setShowUrlInput(true);
    }
  };

  const renderUrlInput = () => (
    <OutsideClickHandler onOutsideClick={() => setShowUrlInput(false)}>
      <StyledLinkInput
        ref={inputEl}
        type="text"
        onKeyDown={onKeyDown}
        onChange={onChange}
        onClick={() => {
          inputEl.current.focus();
        }}
        placeholder="Paste or type a link..."
        value={urlInputValue}
      />
      <StyledCancelLinkButton
        role="button"
        onMouseDown={hideLinkInput}
        onKeyPress={a11yHandleEnterKeyPress(hideLinkInput)}
        tabIndex={0}
      >
        &times;
      </StyledCancelLinkButton>
    </OutsideClickHandler>
  );

  const renderButtons = () => (
    <>
      <MarkButton editor={editor} type="bold" icon="format_bold" />
      <MarkButton editor={editor} type="italic" icon="format_italic" />
      <MarkButton editor={editor} type="underlined" icon="format_underlined" />
      <LinkButton
        editor={editor}
        icon="insert_link"
        onMouseDown={handleLinkInputButton}
      />
    </>
  );

  return (
    <Portal>
      <StyledMenu className="hover-menu" ref={ref}>
        {showUrlInput ? renderUrlInput() : renderButtons()}
      </StyledMenu>
    </Portal>
  );
});

HoverMenu.propTypes = {
  editor: PropTypes.instanceOf(Editor),
};

// renderLinkButton(icon) {
//   const { editor, onClickLink } = this.props;
//   const { value } = editor;
//   const isActive = value.inlines.some(inline => inline.type === 'link');
//   return (
//
//   );

const renderHoverMenu = (editor, args) => {
  const { ref } = args;
  return <HoverMenu ref={ref} editor={editor} />;
};

function HoverMenuPlugin(options) {
  const { menuRef } = options;
  return [RenderEditor({ renderFn: renderHoverMenu, args: { ref: menuRef } })];
}

export default HoverMenuPlugin;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import HandleError from './HandleError';
import HeaderSecondary from './HeaderSecondary';
import HeaderTertiary from './HeaderTertiary';
import TextLink from './TextLink';
import EmailCapture from './EmailCapture';
import SvgMailbox from './svgs/Mailbox';
import SvgMail from './svgs/Mail';
import grid from '../styles/PageGrid.scss';
import styles from '../styles/MagicLinkRequest.scss';

const SEND_LINK_MUTATION = gql`
  mutation requestMagicLinkMutation($email: String!, $afterLoginPath: String) {
    requestMagicLink(email: $email, afterLoginPath: $afterLoginPath) {
      message
    }
  }
`;

const MailBoxImage = () => <SvgMailbox className={styles.mailbox} />;

const MailImage = () => <SvgMail className={styles.mailbox} />;

const Message = ({ label }) => (
  <div className={styles.error}>
    <HeaderTertiary label={label} />
  </div>
);

Message.propTypes = {
  label: PropTypes.string,
};

class MagicLinkRequest extends Component {
  state = {
    email: '',
    requested: false,
  };

  saveToState = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  reset = () => {
    this.setState({ requested: false });
  };

  render() {
    const { email, requested } = this.state;
    const { onSubmit, refuri } = this.props;

    return (
      <div className={grid.value}>
        {!requested ? (
          <>
            <Mutation
              mutation={SEND_LINK_MUTATION}
              variables={{ email, afterLoginPath: refuri }}
            >
              {(requestLink, { error, loading }) => (
                <div className={grid.form}>
                  <div className={styles.textspace}>
                    <HeaderSecondary label="we'll email you a magic link to log in without a password" />
                  </div>

                  <EmailCapture
                    placeholder="what's your email?"
                    email={email}
                    handleEmailChange={this.saveToState}
                    buttonText="send me a link"
                    loading={loading}
                    onSubmit={async () => {
                      await requestLink();
                      onSubmit();
                      this.setState({ requested: true });
                    }}
                  />
                  {error && (
                    <HandleError error={error}>
                      {friendlyMsg => <Message label={friendlyMsg} />}
                    </HandleError>
                  )}
                </div>
              )}
            </Mutation>
          </>
        ) : (
          <div className={grid.form}>
            <div className={styles.textspace}>
              <HeaderSecondary label="check your email" />
              <HeaderTertiary
                label={`we sent an email to you at ${email}. It has a magic link that will sign you in.`}
              />
              <br />
              <br />
              <TextLink
                label="don't see an email? try again"
                onClick={this.reset}
              />
            </div>
          </div>
        )}
        <div className={grid.image}>
          {!requested ? <MailBoxImage /> : <MailImage />}
        </div>
      </div>
    );
  }
}

MagicLinkRequest.propTypes = {
  onSubmit: PropTypes.func,
  refuri: PropTypes.string, // already uri encoded so can be passed to server and put in email directly
};

export default MagicLinkRequest;

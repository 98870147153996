import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import DateNav from './DateNav';
import { useAuthContext } from './context/AuthProvider';
import SnippetEditor from './slate/SnippetEditor';

const Container = styled('div')`
  background: #faf9f7;
  display: flex;
  flex-direction: column;
`;

const ColorSection = styled(`div`)`
  background-image: linear-gradient(
    to bottom,
    #f0003f,
    #dd003a,
    #c90035,
    #b70030,
    #a4002b
  );
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
`;

const CenteredOnPage = styled('div')`
  width: 100%;
  max-width: 610px;
  margin: auto;
  padding: 24px 0 128px 0;
`;

const SnippetsContainer = ({ postId, groupId, due, groupname, frequency }) => {
  const { me } = useAuthContext();
  const renderSnippets = () => {
    // TODO
    // check if person should be allowed to edit them

    return (
      <SnippetEditor
        key="snippet-editor"
        postId={postId}
        groupId={groupId}
        groupname={groupname}
        nickname={me.nickname}
      />
    );
  };

  return (
    <Container>
      <ColorSection>
        <CenteredOnPage>
          <DateNav date={new Date(due)} frequency={frequency} />
          {/* <EditTimeStamp date={new Date(updatedAt)} saving={saving} /> */}
        </CenteredOnPage>
      </ColorSection>
      <CenteredOnPage>{renderSnippets()}</CenteredOnPage>
    </Container>
  );
};

SnippetsContainer.propTypes = {
  postId: PropTypes.string,
  groupId: PropTypes.string,
  due: PropTypes.string,
  groupname: PropTypes.string,
  frequency: PropTypes.number,
};

export default SnippetsContainer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { PLAN_INFO_QUERY, CARD_INFO_QUERY } from './SharedGql';
import { STRIPE } from '../constants';

const STRIPE_API_KEY =
  process.env.NODE_ENV === 'development'
    ? STRIPE.TEST_PK
    : process.env.STRIPE_PK;

const stripePromise = loadStripe(STRIPE_API_KEY);

const UPDATE_CARD = gql`
  mutation UpdateCard($subscribeId: ID!, $stripePaymentMethodId: String!) {
    updateCard(
      subscribeId: $subscribeId
      stripePaymentMethodId: $stripePaymentMethodId
    ) {
      brand
      last4
      expMonth
      expYear
    }
  }
`;

//* just the form *, no submit button. Add form="card-form" and type="submit" to the button you want to submit it.
const CardForm = ({ email, subscribeId, onSuccess, isLoading }) => {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const [
    updateCard,
    { loading: updateCardLoading, error: updateCardError },
  ] = useMutation(UPDATE_CARD);

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error('[stripe not loaded]');
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error: err, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email,
      },
    });

    if (err) {
      console.error('[stripe error]', err);
      setError(err.message.toLowerCase());
    } else {
      try {
        await updateCard({
          variables: { subscribeId, stripePaymentMethodId: paymentMethod.id },
          refetchQueries: [
            { query: CARD_INFO_QUERY, variables: { subscribeId } },
            { query: PLAN_INFO_QUERY, variables: { subscribeId } },
          ],
        });
        onSuccess();
      } catch (e) {
        setError(e.message);
      }
    }
  };

  isLoading(updateCardLoading);
  if (updateCardError) {
    setError(updateCardError.message);
  }

  return (
    <div
      style={{
        padding: '24px 12px',
      }}
    >
      <form id="card-form" onSubmit={handleSubmit}>
        <CardElement />
      </form>
      {/* TODO make the error look like an error */}
      {error && (
        <div
          style={{
            color: '#eb1c26',
          }}
        >
          {error}
        </div>
      )}
    </div>
  );
};

CardForm.propTypes = {
  email: PropTypes.string,
  subscribeId: PropTypes.string,
  onSuccess: PropTypes.func,
  isLoading: PropTypes.func,
};

const UpdateCard = ({ adminEmail, subscribeId, onSuccess, isLoading }) => {
  return (
    <Elements stripe={stripePromise}>
      <CardForm
        email={adminEmail}
        subscribeId={subscribeId}
        onSuccess={onSuccess}
        isLoading={isLoading}
      />
    </Elements>
  );
};

UpdateCard.propTypes = {
  adminEmail: PropTypes.string,
  subscribeId: PropTypes.string,
  onSuccess: PropTypes.func,
  isLoading: PropTypes.func,
};

export default UpdateCard;

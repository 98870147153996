/* eslint react/no-multi-comp: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import PhoneInput from 'react-phone-number-input/input';
import _ from 'lodash';
import standard from '../styles/themes/default.scss';
import solid from '../styles/themes/solid.scss';
import defaultStyle from '../styles/FormInput.scss';

const themes = {
  SOLID: solid,
};

class FormInput extends Component {
  render() {
    const {
      showLabel,
      showValidation,
      validation,
      label,
      name,
      placeholder,
      value,
      innerRef,
      onInputChange,
      onKeyPress,
      autofocus,
      isPhone,
      overrideStyle,
      children,
      theme,
    } = this.props;
    const color = theme ? themes[theme] : standard;
    const styles = _.isEmpty(overrideStyle) ? defaultStyle : overrideStyle;

    const show = showLabel ? styles.show : styles.hidden;
    const val = showValidation ? cn(styles.val, color.val) : '';

    return (
      <div className={styles.inputWrapper}>
        <div className={cn(styles.inputLabel, color.inputLabel, show, val)}>
          {showValidation ? validation : label}
        </div>
        {!isPhone ? (
          <input
            name={name}
            type="text"
            placeholder={placeholder}
            value={value}
            /* eslint-disable */
            autoFocus={autofocus}
            /* eslint-enable */
            spellCheck={false}
            onChange={onInputChange}
            onKeyPress={onKeyPress}
            className={cn(color.input, val)}
            ref={innerRef}
          />
        ) : (
          // <MaskedInput
          //   mask={usPhoneMask}
          //   name={name}
          //   placeholder={placeholder}
          //   value={value}
          //   /* eslint-disable */
          //   autoFocus={autofocus}
          //   /* eslint-enable */
          //   spellCheck={false}
          //   onChange={onInputChange}
          //   onKeyPress={onKeyPress}
          //   className={cn(color.input, val)}
          //   ref={innerRef}
          // />
          <PhoneInput
            defaultCountry="US"
            placeholder={placeholder}
            value={value}
            onChange={onInputChange}
            className={cn(color.input, val)}
            autoFocus={autofocus}
          />
        )}
        {children}
      </div>
    );
  }
}

FormInput.propTypes = {
  name: PropTypes.string,
  showLabel: PropTypes.bool,
  autofocus: PropTypes.bool,
  label: PropTypes.string,
  showValidation: PropTypes.bool,
  validation: PropTypes.string,
  isPhone: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onInputChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  overrideStyle: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  theme: PropTypes.oneOf(['', 'SOLID']),
  children: PropTypes.node,
};

FormInput.defaultProps = {
  theme: '',
  autofocus: false,
  isPhone: false,
  overrideStyle: {},
  onKeyPress: () => {},
};

export default React.forwardRef((props, ref) => (
  <FormInput innerRef={ref} {...props} />
));

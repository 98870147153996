import PropTypes from 'prop-types';
import styled from '@emotion/styled';

export const Button = styled('span')`
  cursor: pointer;
  color: ${props => {
    if (props.reversed) {
      return props.active ? 'white' : '#aaa';
    }
    return props.active ? '#f0003f' : '#d3cec4';
  }};
`;

Button.propTypes = {
  active: PropTypes.bool,
  reversed: PropTypes.bool,
};

export const Icon = styled(({ className, ...rest }) => (
  <span className={`material-icons-outlined ${className}`} {...rest} />
))`
  font-size: 24px;
  vertical-align: text-bottom;
`;

Icon.propTypes = {
  className: PropTypes.string,
};

export const Instruction = styled('div')`
  white-space: pre-wrap;
  margin: 0 -20px 20px;
  padding: 10px 20px;
  font-size: 15px;
  background: #f8f8e8;
`;

Instruction.propTypes = {
  className: PropTypes.string,
};

const Menu = styled('div')`
  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 15px;
  }
`;

Menu.propTypes = {
  className: PropTypes.string,
};

const Toolbar = styled(Menu)`
  position: relative;
  padding: 1px 18px 17px;
  margin: 0 -20px;
  border-bottom: 2px solid #e8e6e1;
  marign-bottom: 20px;
`;

export default Toolbar;

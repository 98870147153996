import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { useAuthContext } from './context/AuthProvider';
import { useGroupContext } from './context/GroupProvider';
import Nav from './Nav';
import PageLoader from './PageLoader';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { me, loading } = useAuthContext();
  const { group, updateGroups } = useGroupContext();
  const location = useLocation();

  /*
  Somewhat of a hack :
  If you linkLogin from magic link, it updates the me in AuthContext
  but not the group/groups in group provider. Then it redirects. This can cause bugs for protected routes because they assume a group context will exist.

  If we hit this condition, just manually update it with the lastest me.
  Better than trying to do it in a useEffect hook of the group provider that listens to AuthContext changes, which causes more bugs.
   */

  useEffect(() => {
    if (me && !group) {
      console.warn('hit me set but group null');
      updateGroups(me.groups);
    }
  }, [group, me, updateGroups]);

  return (
    <Route
      {...rest}
      render={props => {
        if (loading.me) {
          return (
            <>
              <Nav blank fill thick />
              {/* TODO: better copy here and center on page */}
              <PageLoader label="looking you up..." />
            </>
          );
        }

        if (!me) {
          return (
            <Redirect
              to={{
                pathname: '/magiclink',
                state: { from: location.pathname },
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
};

export default PrivateRoute;

import React, { useRef } from 'react';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import styled from '@emotion/styled';
import ButtonRound from './ButtonRound';

const Container = styled.div`
  position: relative;
`;

const formId =
  process.env.NODE_ENV === 'production' && process.env.BACKEND_STAGE === 'prod'
    ? 'RNXvNX'
    : 'dyd95h';
const EMBED_URL = `https://snippets.typeform.com/to/${formId}`;

const WaitlistPopup = () => {
  const embedRef = useRef(undefined);

  const openForm = () => {
    if (embedRef.current) {
      embedRef.current.typeform.open();
    }
  };

  return (
    <Container>
      <ReactTypeformEmbed
        popup
        autoOpen={false}
        url={EMBED_URL}
        hideHeaders
        hideFooters
        style={{ borderRadius: '20px' }}
        autoClose={1000}
        ref={embedRef}
      />
      <ButtonRound text="join the waitlist" onClick={openForm} />
    </Container>
  );
};

export default WaitlistPopup;

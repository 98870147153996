/* eslint react/prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/react-hoc';
import gql from 'graphql-tag';
import * as Sentry from '@sentry/browser';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ClipLoader } from 'react-spinners';
import mutationState from 'react-apollo-mutation-state';
import styles from '../styles/Checkout.scss';

const CheckoutForm = ({
  handleValidation,
  email,
  onSubmit,
  monthly,
  subscribe,
  groupName,
  mutation,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async event => {
    event.preventDefault();

    if (handleValidation()) {
      const cardElement = elements.getElement(CardElement);

      try {
        mutation.set({ loading: true });
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            email,
            // TODO
            // name
            // phone
          },
        });

        if (error) {
          // TODO handle this error with Stripe card source creation;
          console.error(`[error] Stripe payment method creation error:`, error);
          mutation.set({ loading: false, error });
        } else {
          const { data } = await subscribe({
            variables: {
              email,
              groupName,
              stripeSourceId: paymentMethod.id,
              yearly: !monthly,
            },
          });

          console.log('subscribe data:', data);
          mutation.set({ loading: false, error: null });
          onSubmit(data);
        }
      } catch (error) {
        console.error(`Error while trying subscribe mutation: `, error);
        Sentry.captureException(error);
        mutation.set({ loading: false, error });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.checkout}>
      <div className={styles.cardsection}>
        <CardElement
          options={{
            style: {
              base: {
                fontFamily: 'Lato, sans-serif',
                textTransform: 'lowercase',
                marginBottom: '10px',
              },
            },
          }}
        />
      </div>

      <div className={styles.purchasedisclaimer}>
        {`by continuing, you accept our `}
        <a className={styles.termsLink} tabIndex="-1" href="/terms">
          terms
        </a>
        {` and `}
        <a className={styles.termsLink} tabIndex="-1" href="/privacy">
          privacy policy
        </a>
      </div>

      {mutation.loading ? (
        <ClipLoader
          sizeUnit="px"
          size={24}
          color="#F7C948"
          loading={mutation.loading}
        />
      ) : (
        <button type="submit">Subscribe</button>
      )}

      <div className={styles.ssl}>
        <span role="img" aria-label="lock">
          🔒
        </span>{' '}
        SSL Encrypted, Secure Payment
      </div>
    </form>
  );
};

CheckoutForm.propTypes = {
  handleValidation: PropTypes.func,
  onSubmit: PropTypes.func,
  monthly: PropTypes.bool,
  email: PropTypes.string,
  groupName: PropTypes.string,
  subscribe: PropTypes.func,
  mutation: PropTypes.shape({
    set: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.instanceOf(Error),
  }),
};

const SUBSCRIBE_MUTATION = gql`
  mutation SubscribeMutation(
    $email: String!
    $stripeSourceId: String!
    $groupName: String!
    $yearly: Boolean
  ) {
    subscribe(
      email: $email
      stripeSourceId: $stripeSourceId
      groupName: $groupName
      yearly: $yearly
    ) {
      email {
        to
        from
        subject
      }
      inviteUrl
      subscribe {
        id
        payer {
          id
        }
        group {
          id
          name
        }
        stripeSubscriptionId
      }
    }
  }
`;

const withData = graphql(SUBSCRIBE_MUTATION, { name: 'subscribe' });

const withMutationState = mutationState();

export default withMutationState(withData(CheckoutForm));

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';
import styled from '@emotion/styled';
import { humanizeDate } from '../../../../utils/date';

const override = css`
  padding-left: 20px;
`;

const TimeStamp = styled.div(props => ({
  position: props.bottom ? 'absolute' : 'relative',
  bottom: props.bottom && 0,
  paddingBottom: '8px',
  fontFamily: 'Lato, sans-serif',
  fontSize: '11px',
  color: 'hsl(41, 8%, 61%)',
}));

const Saving = styled('div')`
  color: #f0003f;
  font-weight: bold;
  letter-spacing: 0.05em;

  span {
    padding-right: 4px;
    margin-top: -2px;
  }
`;

const SavingIndicator = React.forwardRef(
  ({ date, saving, updateInterval, bottom }, ref) => {
    const [, setState] = useState();

    useEffect(() => {
      const interval = setInterval(() => {
        setState({});
      }, updateInterval);

      return () => clearInterval(interval);
    }, [updateInterval]);

    const timePassed = humanizeDate({ date });
    return (
      <TimeStamp bottom={bottom} ref={ref}>
        {saving ? (
          <Saving>
            <span>saving </span>
            <ClipLoader
              css={override}
              sizeUnit="px"
              size={8}
              color="#f0003f"
              loading={saving}
            />
          </Saving>
        ) : (
          <>
            <span>saved ({timePassed}) </span>
            <span role="img" aria-label="check mark">
              ✅
            </span>
            <br />
            <span>we&apos;ll submit whatever you write here.</span>
          </>
        )}
      </TimeStamp>
    );
  },
);

SavingIndicator.propTypes = {
  saving: PropTypes.bool,
  date: PropTypes.instanceOf(Date).isRequired,
  updateInterval: PropTypes.number,
  bottom: PropTypes.bool,
};

SavingIndicator.defaultProps = {
  saving: false,
  updateInterval: 10000,
  bottom: false,
};

export default SavingIndicator;

/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

// HOC to extract the client friendly message
// can then pass it to any children that needs it,
// which allows different components to handle in context
// errors how they want
//
// in the future, can also put logic here to determine
// what to do based on the name of the apolloError

const HandleError = ({ error, children }) => {
  Sentry.captureException(error);
  let clientMessage;
  let data = {};
  if (error.graphQLErrors) {
    const apolloError = error.graphQLErrors[0];

    const {
      friendlyMsg = 'something went wrong...',
      info = {},
    } = apolloError.data;
    clientMessage = friendlyMsg;
    data = info;
  } else {
    clientMessage = error.message;
  }

  // more logic here if needed to get to a friendly messages

  return <>{children(clientMessage, data)}</>;
};

HandleError.propTypes = {
  error: PropTypes.object,
  children: PropTypes.func,
};

Error.propTypes = {
  error: PropTypes.object,
};

export default HandleError;

/* eslint react/prop-types: 0 */

import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import _ from 'lodash';
import { Icon } from '@iconify/react';
import posed, { PoseGroup } from 'react-pose';
import sparklesIcon from '@iconify/icons-emojione-monotone/sparkles';
import { Button } from './Toolbar';

const prompts = [
  {
    category: 'random',
    emoji: '✨',
    emojiLabel: 'emoji_sparkle',
    title: `here's a lil' inspiration ✨`,
    questions: [
      `how do you feel rn?`,
      `what's been going on at work?`,
      `what's you do for fun this month?`,
      `what's something that surprised you recently?`,
      `how's your dating life?`,
      `what are some things you've been into lately?`,
      `any new hobbies?`,
      `talk about an act of kindness you experienced recently`,
      `what's been tough?`,
      `anything your friends could help you with?`,
      `how much do you miss these homies?`,
      `anything remind you of someone in this group?`,
      `what's something you're learning how to do?`,
      `what's something you need to work on?`,
      `what'd you do this past weekend?`,
      `what's something you're looking forward to?`,
      `any big events or life transitions happen?`,
    ],
  },
  {
    category: 'reflect',
    emoji: '💎',
    emojiLabel: 'emoji_gem',
    title: `the real gems are about what's really going on 💎`,
    questions: [
      `how are you feeling right now and generally? why?`,
      `what's something you're looking forward to?`,
      `what's been tough recently?`,
      `what's something that's shaping you?`,
    ],
  },
  {
    category: 'work',
    emoji: '🏢',
    emojiLabel: 'emoji_office',
    title: `go ahead, rant or rave about 🏢. your homies won't judge you:`,
    questions: [
      `what's something you're proud of?`,
      `what's been a struggle?`,
      `anything you're planning on switching up? (team, role, company, etc)`,
      `what could your friends help you out with?`,
    ],
  },
  {
    category: 'fun',
    emoji: '🎉',
    emojiLabel: `emoji_party`,
    title: 'friends like when friends have fun 🎉',
    questions: [
      `any new hobbies or especially fun old ones?`,
      `what'd you do this past weekend?`,
      `what's have you been into doing lately?`,
    ],
  },
  {
    category: 'people',
    emoji: '🙃',
    emojiLabel: 'emoji_upside_down_person',
    title: 'talk about the people in your life 🙃',
    questions: [
      `how is your family doing?`,
      `who is someone new in your life that you're excited about?`,
      `any recent great experiences with old friends?`,
      `what's been happening lately in your relationship?`,
    ],
  },
];

export const PlaceholderToolbarButton = ({ isActive, handleClick }) => {
  return (
    <Button active={isActive} onMouseDown={handleClick}>
      <Icon icon={sparklesIcon} />
    </Button>
  );
};

const OpacityEntryExit = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
});

const StyledPlaceholderContainer = styled('div')`
  position: relative;
  border-left: 4px solid hsl(48, 95%, 76%);
  padding: 16px;
  color: hsl(41, 8%, 61%);
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 32px;
  z-index: 2;
  transition: opacity 0.2s ease-out;
`;

const StyledChipContainer = styled('span')`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  border-radius: 12rem;
  padding: 0 0.75rem;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  transition-property: margin, max-height, opacity, top;
  height: 2rem;
  line-height: 2rem;
  font-size: 0.8xrem;
  letter-spacing: 0.5;
  background-color: #e3e5ed;
  border: 1px solid #e3e5ed;
  color: #576574;
  margin: 0.25rem;
  ${props =>
    props.variant === 'neutral' &&
    `
            background-color: #fff;
            border: 1px solid #e3e5ed;
            color: #576574;
        `};
  ${props =>
    props.variant === 'outline-brand' &&
    `
            background-color: transparent;
            border: 1px solid rgba(240, 0, 63, .7);
            color: rgba(240, 0, 63, .7);
        `};
  ${props =>
    props.variant === 'brand' &&
    `
            background-color: rgba(240, 0, 63, .7);
            border: 1px solid rgba(240, 0, 63, .7);
            color: #fff;
        `};
`;

const TruncatedText = styled('span')`
  overflow: hidden;
  text-overflow: hidden;
  white-space: nowrap;
`;

const StyledEmojiContainer = styled('span')`
  padding-left: 5px;
`;

const ChipsContainer = styled('div')`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
`;

const StyledPromptsContainer = styled('div')`
  height: 128px;
  overflow: scroll;
`;

const StyledOneQuestion = styled('div')`
  h1 {
    font-size: 19px;
    font-weight: 700;
    line-height: 1.4;
    color: #857f72;
    width: 95%;
  }
  h3 {
    font-size: 16px;
  }
`;

const Emoji = ({ emoji, label }) => (
  <StyledEmojiContainer role="img" aria-label={label}>
    {emoji}
  </StyledEmojiContainer>
);

const Chip = ({
  variant,
  className,
  children,
  index,
  handleMouseDown,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const [hover, setHover] = useState(false);
  const toggleHover = () => setHover(!hover);

  const hoverStyle = hover
    ? { // prettier-ignore
      backgroundColor: 'rgba(240, 0, 63, .7)', // prettier-ignore
      color: '#fff', // prettier-ignore
      cursor: 'pointer', // prettier-ignore
    } // prettier-ignore
    : {};

  return (
    <StyledChipContainer
      className={className}
      style={hoverStyle}
      variant={variant}
      onMouseEnter={() => {
        toggleHover();
        handleMouseEnter(index);
      }}
      onMouseLeave={() => {
        toggleHover();
        handleMouseLeave();
      }}
      onMouseDown={e => handleMouseDown(e, index)}
    >
      <TruncatedText>{children}</TruncatedText>
    </StyledChipContainer>
  );
};

const AnimatedItem = posed.div({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.8 },
});

const Chips = ({
  sections,
  selected,
  handleMouseDown,
  handleMouseEnter,
  handleMouseLeave,
}) => {
  const chips = sections.map((sec, index) => (
    <AnimatedItem data-key={sec.category} key={sec.category}>
      <Chip
        key={sec.category}
        index={index}
        variant={index === selected ? 'brand' : 'outline-brand'}
        handleMouseDown={handleMouseDown}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
      >
        <span>
          {sec.category}
          <Emoji emoji={sec.emoji} label={sec.emojiLabel} />
        </span>
      </Chip>
    </AnimatedItem>
  ));
  return (
    <ChipsContainer>
      <PoseGroup>{chips}</PoseGroup>
    </ChipsContainer>
  );
};

const Question = ({ q }) => (
  <>
    <br /> {q}
  </>
);

const Prompt = ({ section }) => {
  if (!section) return <></>;

  let content;
  if (section.category === 'one') {
    content = (
      <StyledOneQuestion>
        <h1>{section.question}</h1>
        <br />
        <h3>{section.description}</h3>
      </StyledOneQuestion>
    );
  } else {
    const questions = section.subsetQuestions.map(q => (
      <Question key={q} q={q} />
    ));
    content = (
      <>
        {section.title}
        <br />
        {questions}
      </>
    );
  }

  return <StyledPromptsContainer>{content}</StyledPromptsContainer>;
};

const getRandomSubset = (arr, n) =>
  arr.sort(() => 0.5 - Math.random()).slice(0, n);

const updateSubsetQuestions = (allSections, sectionIndexToUpdate) => {
  const newSections = _.cloneDeep(allSections);
  newSections[sectionIndexToUpdate].subsetQuestions = getRandomSubset(
    newSections[sectionIndexToUpdate].possibleQuestions,
    3,
  );

  return newSections;
};

// const reorderSections = (sections, indexToPromote) => {
//   const newSections = _.cloneDeep(sections);
//   const removed = newSections.splice(indexToPromote, 1);
//   newSections.unshift(removed[0]);
//   return newSections;
// };

const Placeholder = ({ editorRef, show, oneQuestion }) => {
  const [selectedChip, setSelectedChip] = useState(0);
  const [hoveredChip, setHoveredChip] = useState(-1);
  const [allSections, setAllSections] = useState([]);
  console.log('oneQuestion', oneQuestion);

  useEffect(() => {
    const sections = prompts.map(section => {
      const { category, emoji, emojiLabel, title, questions } = section;
      return {
        category,
        emoji,
        emojiLabel,
        title,
        subsetQuestions: getRandomSubset(questions, 3),
        possibleQuestions: questions,
      };
    });
    if (oneQuestion) {
      const { question, description } = oneQuestion;
      const oneQuestionSection = {
        category: 'one',
        emoji: '❓',
        emojiLabel: `emoji_question_mark`,
        title: 'after your updates, one ❓ for the group:',
        question,
        description,
      };

      sections.unshift(oneQuestionSection);
    }
    setAllSections(sections);
  }, [oneQuestion]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  }, [editorRef, show]);

  const onSelectChip = (event, chipIndex) => {
    event.preventDefault();
    if (chipIndex !== selectedChip) {
      // const newSections = reorderSections(allSections, chipIndex);
      setSelectedChip(chipIndex);
    } else {
      const clickedSection = allSections[chipIndex];
      if (clickedSection.category !== 'one') {
        const newSections = updateSubsetQuestions(allSections, chipIndex);
        setAllSections(newSections);
      }
    }
  };

  const onEnterHoverChip = chipIndex => setHoveredChip(chipIndex);

  const onLeaveHoverChip = () => setHoveredChip(-1);

  let section;
  if (allSections.length !== 0) {
    section =
      hoveredChip === -1 ? allSections[selectedChip] : allSections[hoveredChip];
  } else {
    section = {
      title: '',
      subsetQuestions: [],
    };
  }

  return (
    <PoseGroup>
      {show && (
        <OpacityEntryExit key="placeholder-animate-entry">
          <StyledPlaceholderContainer>
            <Prompt
              section={section}
              onMouseDown={e => {
                e.preventDefault();
                if (editorRef) {
                  editorRef.current.focus();
                }
              }}
            />
            <Chips
              sections={allSections}
              selected={selectedChip}
              handleMouseDown={onSelectChip}
              handleMouseEnter={onEnterHoverChip}
              handleMouseLeave={onLeaveHoverChip}
            />
          </StyledPlaceholderContainer>
        </OpacityEntryExit>
      )}
    </PoseGroup>
  );
};

export default Placeholder;

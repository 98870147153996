import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import {
  Avatar,
  AvatarMenu,
  ButtonIcon,
  MenuDivider,
  MenuItem,
} from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faUsers,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/browser';
import { useAuthContext } from './context/AuthProvider';
import { useGroupContext } from './context/GroupProvider';
import Logo from './Logo';
import AnotherGroupPopup from './AnotherGroupPopup';
import { timeLeft } from '../utils/date';
import styles from '../styles/Nav.scss';

const NavLink = props => {
  const { to, label } = props;
  return (
    <Link to={to} className={styles.linkbox}>
      <div className={styles.tb}>{label}</div>
      <div className={styles.hoverbar} />
    </Link>
  );
};

NavLink.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
};

const LinkNav = ({ links }) => {
  const navlinks = links.map(l => (
    <NavLink key={l.label} to={l.to} label={l.label} />
  ));

  navlinks.push(<NavLink key="login" to="/magiclink" label="login" />);

  return <div className={cn(styles.right, styles.textLinks)}>{navlinks}</div>;
};

LinkNav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ),
};

LinkNav.defaultProps = {
  links: [],
};

const ProfileMenu = () => {
  const auth = useAuthContext();
  const { me, logout, loading, error } = auth;

  const onLogout = async () => {
    await logout();
  };

  let label = 'log out';
  if (loading.logout) {
    label = 'bye!';
  }

  if (error.logout) {
    label = error.logout.message;
  }

  return (
    <>
      <MenuItem
        label={me.name.toLowerCase()}
        variant="header"
        className={styles.itemHeader}
      />
      <MenuItem
        label={label}
        iconPosition="left"
        icon={<FontAwesomeIcon icon={faSignOutAlt} />}
        onClick={onLogout}
      />
    </>
  );
};

const SelectedGroup = ({ group }) => {
  const { current } = group;

  let subheader;
  if (!current) {
    const {
      subscription: { paused },
    } = group;
    subheader = paused ? 'snippets paused' : 'snippets will start soon';
  } else {
    const { due, tz } = current;
    subheader = `snippets due ${timeLeft({
      due: new Date(due),
      tz,
      short: true,
    })}`;
  }

  return (
    <Link to="/group" className={styles.linknostyle}>
      <li className={styles.selected}>
        <Avatar
          title={group.name}
          assistiveText={group.name}
          initials={group.name}
          size="medium"
          className={styles.avatar}
          style={{ backgroundColor: '#f7c948' }}
        />
        <div className={styles.info}>
          <h1>{group.name}</h1>
          <h2>{subheader}</h2>
        </div>
      </li>
    </Link>
  );
};

SelectedGroup.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    current: PropTypes.shape({
      id: PropTypes.string,
      due: PropTypes.string,
      tz: PropTypes.string,
    }),
    subscription: PropTypes.shape({
      paused: PropTypes.bool,
    }),
  }),
};

const GroupSelect = ({ fill }) => {
  const { group, groups, selectGroup } = useGroupContext();

  const isBiggerScreen = useMediaQuery({
    query: '(min-width: 640px)',
  });

  if (!group) {
    return <></>;
  }

  const GroupItems = groups.map(g => (
    <MenuItem
      label={g.name}
      icon={
        <Avatar
          title={g.name}
          assistiveText={g.name}
          initials={g.name}
          size="x-small"
          className={styles.avatar}
          style={{ backgroundColor: '#f7c948' }}
        />
      }
      onClick={() => {
        selectGroup(g.id);
      }}
      key={g.id}
    />
  ));

  const navButtonVariant = fill ? 'border-inverse' : 'border';
  const navButtonSize = isBiggerScreen ? 'medium' : 'small';

  return (
    <div className={styles.right}>
      <Link to="/snippets" className={styles.linknostyle}>
        <ButtonIcon
          icon={<FontAwesomeIcon icon={faPen} />}
          variant={navButtonVariant}
          size={navButtonSize}
          className={styles.navbutton}
        />
      </Link>
      <Link to="/group" className={styles.linknostyle}>
        <ButtonIcon
          icon={<FontAwesomeIcon icon={faUsers} />}
          variant={navButtonVariant}
          size={navButtonSize}
          className={styles.navbutton}
        />
      </Link>
      <AvatarMenu
        id="avatar-menu"
        title={group.name}
        assistiveText={group.name}
        initials={group.name}
        menuAlignment="right"
        menuSize="small"
        avatarSize={isBiggerScreen ? 'large' : 'medium'}
        className={styles.menu}
      >
        <SelectedGroup group={group} />
        <MenuDivider variant="space" />
        <MenuItem
          label="groups"
          variant="header"
          className={styles.itemHeader}
        />
        {GroupItems}
        <AnotherGroupPopup />
        <MenuDivider variant="space" />
        <ProfileMenu />
      </AvatarMenu>
    </div>
  );
};

GroupSelect.propTypes = {
  fill: PropTypes.bool,
};

GroupSelect.defaultProps = {
  fill: true,
};

const Nav = ({ showAuthLinks, fill, thick, blank }) => {
  const auth = useAuthContext();
  const thickStyle = thick ? styles.thick : '';
  const fillStyle = fill ? styles.fill : '';
  const centerStyle = !showAuthLinks ? styles.center : '';
  const blankStyle = blank ? styles.blank : '';

  let authlinks;
  if (showAuthLinks) {
    if (auth.me) {
      authlinks = <GroupSelect fill={fill} />;
    } else {
      Sentry.configureScope(scope => {
        scope.setUser({});
      });
      authlinks = <LinkNav />;
    }
  }

  let container;
  if (!blank) {
    container = (
      <>
        <div className={styles.logo}>
          <Logo solid={fill} />
        </div>
        {authlinks}
      </>
    );
  }

  return (
    <div className={cn(styles.container, fillStyle, thickStyle, blankStyle)}>
      <div className={cn(styles.nav, centerStyle)}>{container}</div>
    </div>
  );
};

Nav.propTypes = {
  blank: PropTypes.bool,
  showAuthLinks: PropTypes.bool,
  thick: PropTypes.bool,
  fill: PropTypes.bool,
};

Nav.defaultProps = {
  blank: false,
  showAuthLinks: false,
  thick: false,
  fill: false,
};

export default Nav;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { graphql, withApollo } from '@apollo/react-hoc';
import compose from 'lodash.flowright';
import { ApolloClient } from 'apollo-client';
import mutationState from 'react-apollo-mutation-state';
import { PoseGroup } from 'react-pose';
import gql from 'graphql-tag';
import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';
import Card from 'react-rainbow-components/components/Card';
import * as Sentry from '@sentry/browser';
import { Segment } from '../utils/segment';
import { Form, FormSection, ConfScreen } from './Animations';
import ErrorBoundary from './ErrorBoundary';
import Nav from './Nav';
import Section from './Section';
import FormInput from './FormInput';
import Confirmation from './Confirmation';
import AutoNickNameLabel from './AutoNickNameLabel';
import pageStyles from '../styles/FormPage.scss';
import formStyleOverride from '../styles/GroupInfoForm.scss';
import color from '../styles/themes/solid.scss';
import styles from '../styles/InvitedUserPage.scss';
import { COMMON_STRINGS } from '../constants';
import { extractOthersList, semanticList } from '../utils/strings';

const INVITE_QUERY = gql`
  query inviteQuery($inviteId: ID!) {
    invite(inviteId: $inviteId) {
      id
      group {
        id
        name
        admin {
          nickname
        }
        invites {
          email
        }
      }
      email
      accepted
    }
  }
`;

const GROUP_QUERY = gql`
  query groupQuery($inviteCode: String!) {
    groupByInviteCode(inviteCode: $inviteCode) {
      id
      name
      admin {
        nickname
      }
      invites {
        email
      }
    }
  }
`;

const USER_QUERY = gql`
  query userQuery($email: String!) {
    userByEmail(email: $email) {
      id
      email
      name
      nickname
      phone
    }
  }
`;

const VALID_PHONE_QUERY = gql`
  query checkValidPhoneNumberQuery($phone: String!) {
    checkValidPhoneNumber(phone: $phone)
  }
`;

const override = css`
  padding-top: 20px;
`;

const InviteInfo = ({ inviter, groupname, others }) => {
  const otherString = others ? semanticList(others) : '';

  return (
    <Card className={styles.invite} style={{ backgroundColor: '#fce588' }}>
      <h3>{inviter} has invited you to join the snippets group</h3>
      <h1>{groupname}</h1>
      {otherString.length > 0 && (
        <h2>
          along with <span>{otherString}</span>
        </h2>
      )}
    </Card>
  );
};

InviteInfo.propTypes = {
  inviter: PropTypes.string,
  groupname: PropTypes.string,
  others: PropTypes.arrayOf(PropTypes.string),
};

class InvitedUserPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      nickname: '',
      phone: '',
      email: '',
      inviteInfo: {},
      showNicknameField: false,
      showNameValidation: false,
      showNicknameValidation: false,
      showPhoneValidation: false,
      showEmailValidation: false,
      accepted: false,
      acceptedAfterSubmissionEmail: false,
      current: undefined,
      specificInvite: true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddNicknameField = this.handleAddNicknameField.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePhoneInputChange = this.handlePhoneInputChange.bind(this);
  }

  async componentDidMount() {
    Segment.page('Invite Conversion');

    const {
      location: { pathname },
    } = this.props;

    const specificInvite = pathname.includes('/setup'); // otherwise it's /join

    this.setState({
      specificInvite,
    });

    if (specificInvite) {
      // get the invite info, get user info if it's there, update the state
      const { match, client } = this.props;
      const { id } = match.params;

      try {
        const {
          data: { invite },
        } = await client.query({
          query: INVITE_QUERY,
          variables: {
            inviteId: id,
          },
        });

        if (invite) {
          this.setState({
            inviteInfo: {
              inviter: invite.group.admin.nickname,
              groupname: invite.group.name,
              others: extractOthersList(invite.group.invites, invite.email),
            },
            email: invite.email,
            accepted: invite.accepted,
          });

          Segment.group(invite.group.id, {
            name: invite.group.name,
          });

          const {
            data: { userByEmail },
          } = await client.query({
            query: USER_QUERY,
            variables: {
              email: invite.email,
            },
          });

          if (userByEmail) {
            this.setState({
              name: userByEmail.name,
              nickname: userByEmail.nickname,
              phone: userByEmail.phone,
            });
            Segment.identify(userByEmail.id, {
              name: userByEmail.name,
              email: userByEmail.email,
            });
          }
        } else {
          // TODO the invite id in the URL is wrong..., show some user visible error
          console.error(`no invite for this invite id`);
        }
      } catch (err) {
        // there was some error with the invite query
        // TODO show a user visible error
        console.error(`Error querying for the invite based on setup/:id`, err);
        Sentry.captureException(err);
      }
    } else {
      const { match, client } = this.props;
      const { inviteCode } = match.params;

      try {
        const {
          data: { groupByInviteCode },
        } = await client.query({
          query: GROUP_QUERY,
          variables: {
            inviteCode,
          },
        });

        if (groupByInviteCode) {
          this.setState({
            inviteInfo: {
              inviter: groupByInviteCode.admin.nickname,
              groupname: groupByInviteCode.name,
            },
          });
          Segment.group(groupByInviteCode.id, {
            name: groupByInviteCode.name,
          });
        } else {
          console.error(`no group for this invitecode`);

          // TODO show some user visible error in this case
        }
      } catch (err) {
        // TODO show some user visible error, can't fetch the group based on the join link
        console.error(
          `Error querying for the group based on join/:inviteCode`,
          err,
        );
        Sentry.captureException(err);
      }
    }
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      const { name, nickname, phone, email } = this.state;
      if (name && nickname && email && phone) {
        this.handleSubmit();
      }
    }
  }

  handleAddNicknameField() {
    this.setState({
      showNicknameField: true,
    });
  }

  handleInputChange(event) {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    const {
      showNameValidation,
      showNicknameValidation,
      showEmailValidation,
      showNicknameField,
    } = this.state;

    if (!showNicknameField && name === 'name') {
      const nickname = value.split(' ')[0];
      this.setState({
        nickname,
      });
    }

    switch (name) {
      case 'name':
        if (showNameValidation) this.setState({ showNameValidation: false });
        break;
      case 'nickname':
        if (showNicknameValidation)
          this.setState({ showNicknameValidation: false });
        break;
      case 'email':
        if (showEmailValidation) this.setState({ showEmailValidation: false });
        break;
      default:
        break;
    }

    this.setState({
      [name]: value,
    });
  }

  handlePhoneInputChange(value) {
    const { showPhoneValidation } = this.state;
    if (showPhoneValidation) this.setState({ showPhoneValidation: false });

    this.setState({
      phone: value || '',
    });
  }

  async handleSubmit() {
    const {
      name,
      nickname,
      phone,
      email,
      showNicknameField,
      specificInvite,
    } = this.state;
    if (!name || !nickname || !email || !phone) {
      this.setState({
        showNameValidation: !name,
        showNicknameValidation: showNicknameField && !nickname,
        showEmailValidation: !email,
        showPhoneValidation: !phone,
      });
    } else {
      const emailIsValid = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      );

      if (!emailIsValid) {
        this.setState({
          showEmailValidation: true,
        });
      } else {
        const { match, mutation, client } = this.props;
        mutation.set({ loading: true });

        const {
          data: { checkValidPhoneNumber: isValidPhone },
        } = await client.query({
          query: VALID_PHONE_QUERY,
          variables: {
            phone,
          },
        });

        if (!isValidPhone) {
          this.setState({
            showPhoneValidation: true,
          });
          mutation.set({ loading: false });
        } else if (specificInvite) {
          const { id } = match.params;
          const { acceptInvite } = this.props;
          acceptInvite({
            variables: {
              inviteId: id,
              name,
              nickname,
              phone,
              email,
            },
          })
            /* eslint-disable no-unused-vars */
            .then(({ data }) => {
              /* eslint-disable no-unused-vars */
              mutation.set({ loading: false, error: null });

              const {
                acceptInvite: {
                  user,
                  acceptedAfterSubmissionEmail,
                  current = undefined,
                },
              } = data;

              this.setState({
                accepted: true,
                acceptedAfterSubmissionEmail,
                current,
              });
              Segment.identify(user.id, {
                name: user.name,
                email: user.email,
              });
            })
            .catch(error => {
              Sentry.captureException(error);
              mutation.set({ loading: false, error });
              console.error(error);
            });
        } else {
          const { inviteCode } = match.params;
          const { joinGroup } = this.props;
          joinGroup({
            variables: {
              inviteCode,
              name,
              nickname,
              phone,
              email,
            },
          })
            /* eslint-disable no-unused-vars */
            .then(({ data }) => {
              /* eslint-disable no-unused-vars */
              mutation.set({ loading: false, error: null });

              const {
                joinGroup: {
                  user,
                  acceptedAfterSubmissionEmail,
                  current = undefined,
                },
              } = data;
              this.setState({
                accepted: true,
                acceptedAfterSubmissionEmail,
                current,
              });
              Segment.identify(user.id, {
                name: user.name,
                email: user.email,
              });
            })
            .catch(error => {
              Sentry.captureException(error);
              mutation.set({ loading: false, error });
              console.error(error);
            });
        }
      }
    }
  }

  render() {
    const {
      name,
      nickname,
      phone,
      email,
      inviteInfo,
      showNicknameField,
      showNameValidation,
      showNicknameValidation,
      showPhoneValidation,
      showEmailValidation,
      accepted,
      acceptedAfterSubmissionEmail,
      current,
    } = this.state;

    const { mutation } = this.props;
    const { inviter, groupname, others } = inviteInfo;

    return (
      <ErrorBoundary>
        <div className={cn(pageStyles.page, color.page)}>
          <Nav showAuthLinks={false} fill />
          <div className={pageStyles.formsections}>
            <PoseGroup>
              {!accepted && (
                <Form key="pose-form">
                  <FormSection>
                    <InviteInfo
                      inviter={inviter}
                      groupname={groupname}
                      others={others}
                    />
                  </FormSection>
                  <FormSection>
                    <Section
                      name="hi there, let's get you set up"
                      description={COMMON_STRINGS.PHONE_DESCRIPTION}
                      lowspace
                    >
                      <div className={styles.fullname}>
                        <FormInput
                          name="name"
                          autofocus
                          showLabel={name !== ''}
                          showValidation={showNameValidation}
                          value={name}
                          label="full name"
                          validation="required"
                          placeholder="what's your full name?"
                          onInputChange={this.handleInputChange}
                          onKeyPress={this.handleKeyPress}
                          overrideStyle={formStyleOverride}
                          theme="SOLID"
                        >
                          <AutoNickNameLabel
                            showLabel={!showNicknameField && name !== ''}
                            value={nickname}
                            onClick={this.handleAddNicknameField}
                            overrideStyle={color.bottomlabel}
                          />
                        </FormInput>
                      </div>
                      {showNicknameField && (
                        <div className={styles.nickname}>
                          <FormInput
                            name="nickname"
                            showLabel={nickname !== ''}
                            showValidation={showNicknameValidation}
                            value={nickname}
                            label="what do your friends call you?"
                            validation="required"
                            placeholder="they call me..."
                            onInputChange={this.handleInputChange}
                            onKeyPress={this.handleKeyPress}
                            overrideStyle={formStyleOverride}
                            theme="SOLID"
                          />
                        </div>
                      )}
                      <div className={pageStyles.phone}>
                        <FormInput
                          name="phone"
                          isPhone
                          showLabel={phone !== ''}
                          showValidation={showPhoneValidation}
                          value={phone}
                          label="phone number"
                          validation="number isn't valid"
                          placeholder="and your phone number?"
                          onInputChange={this.handlePhoneInputChange}
                          onKeyPress={this.handleKeyPress}
                          overrideStyle={formStyleOverride}
                          theme="SOLID"
                        />
                      </div>
                      <div className={pageStyles.email}>
                        <FormInput
                          name="email"
                          showLabel={email !== ''}
                          showValidation={showEmailValidation}
                          value={email}
                          label="email"
                          validation="email isn't valid"
                          placeholder="and your email?"
                          onInputChange={this.handleInputChange}
                          onKeyPress={this.handleKeyPress}
                          overrideStyle={formStyleOverride}
                          theme="SOLID"
                        />
                      </div>
                      {mutation.loading ? (
                        <ClipLoader
                          css={override}
                          sizeUnit="px"
                          size={24}
                          color="#F7C948"
                          loading={mutation.loading}
                        />
                      ) : (
                        <button
                          type="button"
                          onClick={this.handleSubmit}
                          className={styles.join}
                        >
                          join this group
                        </button>
                      )}
                    </Section>
                  </FormSection>
                </Form>
              )}
              {accepted && (
                <ConfScreen key="pose-conf">
                  <Confirmation
                    groupname={groupname}
                    created={false}
                    acceptedAfterSubmissionEmail={acceptedAfterSubmissionEmail}
                    current={current}
                  />
                </ConfScreen>
              )}
            </PoseGroup>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

InvitedUserPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      inviteCode: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  acceptInvite: PropTypes.func,
  joinGroup: PropTypes.func,
  client: PropTypes.instanceOf(ApolloClient),
  mutation: PropTypes.shape({
    set: PropTypes.func,
    loading: PropTypes.bool,
    error: PropTypes.instanceOf(Error),
  }),
};

const ACCEPT_INVITE = gql`
  mutation AcceptInvite(
    $inviteId: ID!
    $email: String!
    $name: String!
    $nickname: String!
    $phone: String!
  ) {
    acceptInvite(
      inviteId: $inviteId
      email: $email
      name: $name
      nickname: $nickname
      phone: $phone
    ) {
      user {
        id
        name
        email
        groups {
          id
          name
        }
      }
      acceptedAfterSubmissionEmail
      current {
        due
        tz
      }
    }
  }
`;

const JOIN_GROUP = gql`
  mutation JoinGroup(
    $inviteCode: String!
    $email: String!
    $name: String!
    $nickname: String!
    $phone: String!
  ) {
    joinGroup(
      inviteCode: $inviteCode
      email: $email
      name: $name
      nickname: $nickname
      phone: $phone
    ) {
      user {
        id
        name
        email
        groups {
          id
          name
        }
      }
      acceptedAfterSubmissionEmail
      current {
        due
        tz
      }
    }
  }
`;

const withMutationState = mutationState();

export default withMutationState(
  compose(
    withApollo,
    graphql(ACCEPT_INVITE, { name: 'acceptInvite' }),
    graphql(JOIN_GROUP, { name: 'joinGroup' }),
  )(InvitedUserPage),
);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Segment } from '../utils/segment';
import ErrorBoundary from './ErrorBoundary';
import Nav from './Nav';
import Conversion from './Conversion';
import pageStyles from '../styles/FormPage.scss';
import color from '../styles/themes/solid.scss';

const ConversionPage = ({ match }) => {
  const { id, groupname } = match.params;
  const decodedGroupname = decodeURIComponent(groupname || '');

  useEffect(() => {
    Segment.page('Group Conversion');
    Segment.identify(id);
  });

  return (
    <ErrorBoundary>
      <div className={cn(pageStyles.page, color.page)}>
        <Nav showAuthLinks={false} fill />
        <Conversion userId={id} initialGroupName={decodedGroupname} />
      </div>
    </ErrorBoundary>
  );
};

ConversionPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      groupname: PropTypes.string,
    }),
  }),
};

export default ConversionPage;

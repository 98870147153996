import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import styles from '../styles/BillingSection.scss';
import color from '../styles/themes/solid.scss';
import { STRIPE } from '../constants';
import { getCancelDate } from '../utils/date';

const STRIPE_API_KEY =
  process.env.NODE_ENV === 'development'
    ? STRIPE.TEST_PK
    : process.env.STRIPE_PK;

const stripePromise = loadStripe(STRIPE_API_KEY);

const Title = () => (
  <div className={styles.title}>
    <h1 className={color.title}>start your free trial</h1>
  </div>
);

const BillingTable = ({ monthly }) => (
  <div className={styles.summary}>
    <div className={styles.lineitems}>
      <div className={styles.lineitem}>
        <span className={styles.summaryLabel}>3-Month Free Trial</span>
        <span className={styles.summaryAmount}>$0</span>
      </div>
      <div className={styles.lineitem}>
        <span className={styles.summaryLabel}>Group Subscription</span>
        <span className={styles.summaryAmount}>{monthly ? '$7' : '$60'}</span>
      </div>
      <div className={styles.lineitem}>
        <span className={styles.summaryLabel}>Intro Discount</span>
        <span className={cn(styles.summaryAmount, styles.discounted)}>
          {monthly ? '-$2' : '-50%'}
        </span>
      </div>
    </div>
    <div className={styles.finalline}>
      <span className={styles.total}>total</span>
      <span> billed {monthly ? 'monthly' : 'annually'}</span>
      <span className={styles.summaryAmount}>
        {monthly ? '$5/group/mo' : '$30/group/yr'}
      </span>
    </div>
    <div className={styles.summaryCaveat}>
      <span>starting on {getCancelDate(Date.now(), STRIPE.TRIAL_LENGTH)}</span>
    </div>
  </div>
);

BillingTable.propTypes = {
  monthly: PropTypes.bool,
};

function Payment(props) {
  const { validation, onSubmit, monthly, email, groupname } = props;

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        handleValidation={validation}
        onSubmit={onSubmit}
        monthly={monthly}
        email={email}
        groupName={groupname}
      />
    </Elements>
  );
}

Payment.propTypes = {
  validation: PropTypes.func,
  onSubmit: PropTypes.func,
  monthly: PropTypes.bool,
  email: PropTypes.string,
  groupname: PropTypes.string,
};

const BillingSection = props => {
  const { validation, onSubmit, monthly, email, groupname } = props;
  return (
    <div className={cn(styles.section, color.section)}>
      <Title />
      <BillingTable monthly={monthly} />
      <Payment
        validation={validation}
        onSubmit={onSubmit}
        monthly={monthly}
        email={email}
        groupname={groupname}
      />
    </div>
  );
};

BillingSection.propTypes = {
  validation: PropTypes.func,
  onSubmit: PropTypes.func,
  monthly: PropTypes.bool,
  email: PropTypes.string,
  groupname: PropTypes.string,
};

export default BillingSection;

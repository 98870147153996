import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`),
    );
  }

  if (networkError) console.error(`[Network error]: ${networkError}`);
});

let endpoint;
if (process.env.NODE_ENV === 'development') {
  endpoint = `http://localhost:4000/graphql`;
} else if (process.env.BACKEND_STAGE === 'staging') {
  endpoint = `https://staging-api.dosnippets.com/graphql`;
} else {
  endpoint = `https://api.dosnippets.com/graphql`;
}

const httpLink = new HttpLink({
  uri: endpoint,
  credentials: 'include',
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache(),
});

export default client;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Segment } from '../utils/segment';
import { useGroupContext } from './context/GroupProvider';
import Nav from './Nav';
import Group from './Group';
import ErrorBoundary from './ErrorBoundary';
import pageStyles from '../styles/FormPage.scss';
import color from '../styles/themes/solid.scss';

const GroupPage = ({ match }) => {
  const { groupId } = match.params;
  const { group, selectGroup } = useGroupContext();
  const [useParam, setUseParam] = useState(!!groupId);

  useEffect(() => {
    Segment.page('Group');

    if (useParam) {
      if (group) {
        if (groupId !== group.id) {
          selectGroup(groupId);
          setUseParam(false);
        }
      }
    }
  }, [group, groupId, match.params, selectGroup, useParam]);

  return (
    <ErrorBoundary>
      <div className={cn(pageStyles.page, color.page)}>
        <Nav showAuthLinks fill thick />
        <div className={pageStyles.formsections}>
          {group && <Group groupId={group.id} />}
        </div>
      </div>
    </ErrorBoundary>
  );
};

GroupPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.string,
    }),
  }),
};

export default GroupPage;

import React, { useRef } from 'react';
import { MenuItem } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { ReactTypeformEmbed } from 'react-typeform-embed';
import styled from '@emotion/styled';
import { useAuthContext } from './context/AuthProvider';

// NOTE: need relative positioning otherwise messed
// up how click events work with the embed;
const StyledContainer = styled.div`
  position: relative;
`;

const AnotherGroupPopup = () => {
  const { me } = useAuthContext();
  const embedRef = useRef(undefined);

  const formId =
    process.env.NODE_ENV === 'production' &&
    process.env.BACKEND_STAGE === 'prod'
      ? 'hE1QrP'
      : 'DWXd3x';
  const EMBED_URL = `https://snippets.typeform.com/to/${formId}?email=${me.email}`;

  const openForm = () => {
    if (embedRef.current) {
      embedRef.current.typeform.open();
    }
  };

  return (
    <StyledContainer>
      <ReactTypeformEmbed
        popup
        autoOpen={false}
        url={EMBED_URL}
        hideHeaders
        hideFooters
        style={{ borderRadius: '20px' }}
        autoClose={1000}
        ref={embedRef}
      />
      <MenuItem
        label="start another group"
        iconPosition="left"
        icon={<FontAwesomeIcon icon={faPlus} />}
        onClick={openForm}
      />
    </StyledContainer>
  );
};

export default AnotherGroupPopup;

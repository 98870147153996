import React, { useEffect } from 'react';
import cn from 'classnames';
import { Segment } from '../utils/segment';
import WaitlistPopup from './WaitlistPopup';
import Nav from './Nav';
import HeaderPrimary from './HeaderPrimary';
import HeaderSecondary from './HeaderSecondary';
import ErrorBoundary from './ErrorBoundary';
import SvgHero from './svgs/Hero';
import How from './How';
import styles from '../styles/LandingPage.scss';
import grid from '../styles/LandingPageGrid.scss';

function HeroImage() {
  return <SvgHero className={styles.hero} />;
}

const ValueProp = () => (
  <section className={cn(styles.vp, grid.value)}>
    <div className={grid.signup}>
      <div className={styles.vptext}>
        <HeaderPrimary />
        <div className={styles.vptextsecond}>
          <HeaderSecondary label="authentic updates from the group of people you care most about" />
        </div>
      </div>
      <WaitlistPopup />
    </div>
    <div className={cn(styles.heroContainer, grid.image)}>
      <HeroImage />
    </div>
  </section>
);

const LandingPage = () => {
  useEffect(() => {
    Segment.page('Landing');
  });

  return (
    <ErrorBoundary>
      <>
        <div className={styles.top}>
          <Nav showAuthLinks />
          <ValueProp />
        </div>
        <How />
      </>
    </ErrorBoundary>
  );
};

export default LandingPage;

import gql from 'graphql-tag';
import * as Sentry from '@sentry/browser';
import { Segment } from '../utils/segment';

export const CURRENT_USER_QUERY = gql`
  query {
    me {
      id
      email
      name
      nickname
      groups {
        id
        name
        frequency
        current {
          id
          due
          tz
        }
        subscription {
          paused
        }
      }
    }
  }
`;

export const LINK_LOGIN_MUTATION = gql`
  mutation linkLoginMutation($linkToken: String!) {
    loginWithLink(linkToken: $linkToken) {
      id
      email
      name
      nickname
      groups {
        id
        name
        frequency
        current {
          id
          due
          tz
        }
        subscription {
          paused
        }
      }
    }
  }
`;

export const VALIDATE_SHORT_URL_MUTATION = gql`
  mutation validateShortUrlMutation($shortId: String!) {
    validateShortUrl(shortId: $shortId) {
      autoLogin
      route
    }
  }
`;

export const LOG_OUT_MUTATION = gql`
  mutation LOG_OUT_MUTATION {
    logout {
      message
    }
  }
`;

export const logUser = me => {
  const { id: userId, email, name } = me;

  Segment.identify(userId, {
    email,
    name,
  });

  // groups.forEach(g => {
  //   const { id, name: groupname } = g;
  //   Segment.group(id, {
  //     name: groupname,
  //   });
  // });

  Sentry.configureScope(scope => {
    scope.setUser({ email: me.email });
  });
};

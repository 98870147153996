import React from 'react';
import PropTypes from 'prop-types';
import { a11yHandleEnterKeyPress } from '../utils/a11y';
import styles from '../styles/Headers.scss';

const TextLink = props => {
  const { label, onClick } = props;
  return (
    <div
      className={styles.tb}
      onClick={onClick}
      onKeyPress={a11yHandleEnterKeyPress(onClick)}
      tabIndex={0}
      name="link"
      role="button"
    >
      {label}
    </div>
  );
};

TextLink.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default TextLink;

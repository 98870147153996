import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from 'react-rainbow-components/components/Card';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from '@emotion/styled';
import { getTimezoneAbbrev, formatDuedate } from '../utils/date';
import styles from '../styles/Confirmation.scss';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0 0;
  align-items: center;
`;

const StyledInput = styled.input`
  height: 20px;
  width: 300px;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  border: 1px #a39e93 solid;
  border-radius: 4px;
  padding: 4px;
  color: #423d33;
  outline: none;
  cursor: pointer;
`;

const StyledButton = styled.button`
  margin-left: -4px;
  background-color: #f0003f;
  border-radius: 4px;
  border: none;
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding: 8px 12px;
  outline: none;
`;

const CopyLink = ({ onCopy, copy, inviteUrl }) => (
  <CopyToClipboard text={inviteUrl} onCopy={onCopy}>
    <StyledContainer>
      <StyledInput value={inviteUrl} readOnly />
      <StyledButton>{copy}</StyledButton>
    </StyledContainer>
  </CopyToClipboard>
);

CopyLink.propTypes = {
  onCopy: PropTypes.func,
  copy: PropTypes.string,
  inviteUrl: PropTypes.string,
};

const InviteInstructions = ({ subject, from, to, inviteUrl }) => {
  const [fromCopy, setFromCopy] = useState('copy');
  const [toCopy, setToCopy] = useState('copy');
  const [subjectCopy, setSubjectCopy] = useState('copy');
  const [linkCopy, setLinkCopy] = useState('copy');

  const handleCopy = setter => {
    setter('copied!');
    setTimeout(() => {
      setter('copy');
    }, 2000);
  };

  return (
    <div className={styles.cta}>
      <h3>now, let&apos;s add your homies...</h3>
      <h4>
        we just sent you an email with these details. search your inbox for it:
      </h4>
      <table>
        <tbody>
          <CopyToClipboard
            text={from}
            onCopy={() => {
              handleCopy(setFromCopy);
            }}
          >
            <tr>
              <td className={styles.fieldName}>from</td>
              <td className={styles.field}>{from}</td>
              <td>
                <span className={styles.copy}>{fromCopy}</span>
              </td>
            </tr>
          </CopyToClipboard>
          <CopyToClipboard
            text={to}
            onCopy={() => {
              handleCopy(setToCopy);
            }}
          >
            <tr>
              <td className={styles.fieldName}>to</td>
              <td className={styles.field}>{to}</td>
              <td>
                <span className={styles.copy}>{toCopy}</span>
              </td>
            </tr>
          </CopyToClipboard>
          <CopyToClipboard
            text={subject}
            onCopy={() => {
              handleCopy(setSubjectCopy);
            }}
          >
            <tr>
              <td className={styles.fieldName}>subject</td>
              <td className={styles.field}>{subject}</td>
              <td>
                <span className={styles.copy}>{subjectCopy}</span>
              </td>
            </tr>
          </CopyToClipboard>
        </tbody>
      </table>
      <h4>
        <span>cc your friends on a reply to that email</span> and they&apos;ll
        be automatically added to the group.
      </h4>
      <h3>OR</h3>
      <h4>
        <span>text your friends this invite link</span> and they can join this
        group directly
      </h4>
      <CopyLink
        onCopy={() => {
          handleCopy(setLinkCopy);
        }}
        copy={linkCopy}
        inviteUrl={inviteUrl}
      />
    </div>
  );
};

InviteInstructions.propTypes = {
  subject: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  inviteUrl: PropTypes.string,
};

const AddedToGroupInstructions = ({ acceptedAfter, current }) => {
  const cycleStarted = !!current;
  const dueString = cycleStarted
    ? `are next due on ${formatDuedate(current.due, current.tz)}!`
    : '';

  return (
    <div className={styles.cta}>
      <h3>snippets {cycleStarted ? dueString : 'will start soon!'}</h3>
      <h4>
        {acceptedAfter
          ? 'we just sent you an email. reply with your first set of snippets for the group.'
          : `we'll send you an email reminder when it's time. just reply to that email to submit.`}

        <br />
        <br />
      </h4>
      <h4>
        be sure to submit before the sunday at midnight{' '}
        {cycleStarted && getTimezoneAbbrev(current.tz)} deadline.
      </h4>
    </div>
  );
};

AddedToGroupInstructions.propTypes = {
  acceptedAfter: PropTypes.bool,
  current: PropTypes.shape({
    due: PropTypes.string,
    tz: PropTypes.string,
  }),
};

// confirmation screen for either the created or joined group page
const Confirmation = ({
  groupname,
  created,
  to,
  from,
  subject,
  inviteUrl,
  acceptedAfterSubmissionEmail,
  current,
}) => {
  return (
    <>
      <Card className={styles.confirmation}>
        <div className={styles.banner}>
          <h3>you {created ? 'created' : 'joined'} the snippets group</h3>
          <h1>{groupname.toLowerCase()}</h1>
          <h2>
            woohoo{' '}
            <span role="img" aria-label="party emoji">
              🎉
            </span>
          </h2>
        </div>
        {created ? (
          <InviteInstructions
            groupname={groupname}
            to={to}
            from={from}
            subject={subject}
            inviteUrl={inviteUrl}
          />
        ) : (
          <AddedToGroupInstructions
            acceptedAfter={acceptedAfterSubmissionEmail}
            current={current}
          />
        )}
      </Card>
    </>
  );
};

Confirmation.propTypes = {
  groupname: PropTypes.string,
  created: PropTypes.bool,
  to: PropTypes.string,
  from: PropTypes.string,
  subject: PropTypes.string,
  inviteUrl: PropTypes.string,
  acceptedAfterSubmissionEmail: PropTypes.bool,
  current: PropTypes.shape({
    tz: PropTypes.string,
    due: PropTypes.string,
  }),
};

export default Confirmation;

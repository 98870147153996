import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/Headers.scss';

const HeaderSecondary = props => {
  const { label } = props;
  return (
    <div className={styles.hsecondary}>
      <h3>{label}</h3>
    </div>
  );
};

HeaderSecondary.propTypes = {
  label: PropTypes.string,
};

export default HeaderSecondary;

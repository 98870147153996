/* eslint import/no-webpack-loader-syntax: 0 */

import React from 'react';
import PropTypes from 'prop-types';
import '!style-loader!css-loader!emoji-mart/css/emoji-mart.css';
import data from 'emoji-mart/data/apple.json';
import { Portal } from 'react-portal';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from '@emotion/styled';
import { NimblePicker } from 'emoji-mart';
import { Button, Icon } from './Toolbar';

export const EmojiToolbarButton = ({ isActive, handleClick }) => (
  <Button active={isActive} onMouseDown={handleClick}>
    <Icon>emoji_emotions</Icon>
  </Button>
);

EmojiToolbarButton.propTypes = {
  isActive: PropTypes.bool,
  handleClick: PropTypes.func,
};

const StyledMenu = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  position: absolute;
  z-index: 1;
  bottom: 20px;
  right: 20px;
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.75s;
`;

const EmojiMenu = ({ show, handleClose, editorRef }) => {
  const onSelectEmoji = emoji => {
    const editor = editorRef.current;
    if (!editor) return;
    editor.insertText(emoji.native);
  };

  return (
    <Portal>
      <StyledMenu show={show} className="emoji-menu">
        <OutsideClickHandler onOutsideClick={handleClose} disabled={!show}>
          <NimblePicker
            set="apple"
            data={data}
            autoFocus
            onSelect={onSelectEmoji}
            emojiSize={20}
            perLine={9}
            color="#f0003f"
            emoji=":point_up:"
            title="pick your emoji..."
          />
        </OutsideClickHandler>
      </StyledMenu>
    </Portal>
  );
};

EmojiMenu.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  editorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default EmojiMenu;

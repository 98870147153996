import Hotkey from '../helpers/Hotkey';
import RenderMark from '../helpers/RenderMark';

function Strikethrough(options) {
  const { type } = options;
  return [
    Hotkey({
      hotkey: 'mod+alt+s',
      fn: editor => editor.toggleMark(type),
    }),
    RenderMark({
      type,
      renderFn: (attributes, children) => {
        return <s {...attributes}>{children}</s>;
      },
    }),
  ];
}

export default Strikethrough;

import React from 'react';
import PropTypes from 'prop-types';
import { BounceLoader } from 'react-spinners';
import styles from '../styles/PageLoader.scss';

const PageLoader = props => {
  const { label } = props;
  return (
    <div className={styles.box}>
      <div className={styles.label}>{label}</div>
      <BounceLoader sizeUnit="px" size={100} color="#f0003f" loading />
    </div>
  );
};

PageLoader.propTypes = {
  label: PropTypes.string,
};
export default PageLoader;

import React from 'react';

const SvgMailbox = props => (
  <svg width={754} viewBox="0 0 754 575" {...props}>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="mailbox_svg__b">
        <stop stopColor="#FF6663" stopOpacity={0} offset="0%" />
        <stop stopOpacity={0.1} offset="100%" />
      </linearGradient>
      <path
        d="M0 0h58v236a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V0z"
        id="mailbox_svg__a"
      />
      <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="mailbox_svg__c">
        <stop stopOpacity={0.3} offset="0%" />
        <stop stopColor="#FF6663" stopOpacity={0} offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="-19.979%"
        x2="50%"
        y2="100%"
        id="mailbox_svg__f"
      >
        <stop stopColor="#9FB1FB" stopOpacity={0} offset="0%" />
        <stop stopOpacity={0.272} offset="100%" />
      </linearGradient>
      <path
        d="M69 89c-1.317-15.519 2.716-28.388 12-38 8.637-9.709 20.656-14.644 36-15 1.49-.037 2.845 1.233 3 3 .63 15.143-3.429 27.541-12 37-8.445 8.885-19.385 13.771-33 15l-22 81h-6l22-83z"
        id="mailbox_svg__e"
      />
      <path
        d="M39 63l41 109h-6L33 66C19.697 60.646 10.043 52.546 5 41-1.212 29.513-1.49 16.434 4 2 4.23.458 5.912-.36 7 0c15.048 4.976 25.202 13.246 31 25 5.448 11.262 5.906 23.821 1 38z"
        id="mailbox_svg__g"
      />
      <path
        d="M0 0h349c55.228 0 100 44.772 100 100v233a3 3 0 0 1-3 3H0V0z"
        id="mailbox_svg__h"
      />
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="mailbox_svg__j">
        <stop stopColor="#B0B0F9" stopOpacity={0} offset="0%" />
        <stop stopOpacity={0.05} offset="100%" />
      </linearGradient>
      <path
        d="M420 336H10c-5.523 0-10-4.477-10-10s4.477-10 10-10h180V115.08C190 51.523 241.487 0 305 0s115 51.523 115 115.08V336z"
        id="mailbox_svg__i"
      />
      <path
        d="M95 0c52.467 0 95 45.39 95 101.38V296H0V101.38C0 45.39 42.533 0 95 0z"
        id="mailbox_svg__k"
      />
      <linearGradient
        x1="-27.422%"
        y1="50%"
        x2="100%"
        y2="50%"
        id="mailbox_svg__l"
      >
        <stop stopColor="#464D89" stopOpacity={0} offset="0%" />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="mailbox_svg__o">
        <stop stopColor="#E6E6EF" stopOpacity={0} offset="0%" />
        <stop stopOpacity={0.05} offset="100%" />
      </linearGradient>
      <path
        d="M5 0h210a5 5 0 0 1 5 5v157a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5z"
        id="mailbox_svg__n"
      />
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="143.565%"
        id="mailbox_svg__r"
      >
        <stop stopColor="#F0F0F6" stopOpacity={0} offset="0%" />
        <stop stopOpacity={0.05} offset="100%" />
      </linearGradient>
      <path
        d="M0 147.833C68.502 114.611 105.169 98 110 98c4.831 0 41.498 16.611 110 49.833V167H0v-19.167z"
        id="mailbox_svg__q"
      />
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="mailbox_svg__s">
        <stop stopOpacity={0.1} offset="0%" />
        <stop stopColor="#F0F1F5" stopOpacity={0} offset="100%" />
      </linearGradient>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="143.565%"
        id="mailbox_svg__u"
      >
        <stop stopColor="#F7F7F8" stopOpacity={0} offset="0%" />
        <stop stopOpacity={0.05} offset="100%" />
      </linearGradient>
      <path
        d="M0 57.857V0h220v57.857C151.498 91.286 114.831 108 110 108c-4.831 0-41.498-16.714-110-50.143z"
        id="mailbox_svg__t"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(444 336)">
        <mask id="mailbox_svg__d" fill="#fff">
          <use xlinkHref="#mailbox_svg__a" />
        </mask>
        <use fill="#FF6663" xlinkHref="#mailbox_svg__a" />
        <use fill="url(#mailbox_svg__b)" xlinkHref="#mailbox_svg__a" />
        <path
          d="M58 134.455v33.59c-.332.035-.665.067-1 .097l-10.679 39.239 11.323 30.041A3 3 0 0 1 55 239h-2.868l-8.469-21.852L37.716 239h-6.065l8.328-31.357L15 143.193c-5.693-2.288-10.719-5.076-15-8.422V82c9.151 4.938 15.747 11.671 20 20.275 5.448 11.239 5.906 23.773 1 37.923l21.641 57.42L51 166.146c-1.05-12.346 1.3-23.012 7-31.691zM0 0h58v66z"
          fill="url(#mailbox_svg__c)"
          mask="url(#mailbox_svg__d)"
        />
      </g>
      <g transform="translate(416 403)">
        <use fill="#403F9F" xlinkHref="#mailbox_svg__e" />
        <use fill="url(#mailbox_svg__f)" xlinkHref="#mailbox_svg__e" />
      </g>
      <g transform="translate(416 403)">
        <use fill="#403F9F" xlinkHref="#mailbox_svg__g" />
        <use fill="url(#mailbox_svg__f)" xlinkHref="#mailbox_svg__g" />
      </g>
      <g transform="translate(305)">
        <use fill="#403F9F" xlinkHref="#mailbox_svg__h" />
        <use fill="url(#mailbox_svg__f)" xlinkHref="#mailbox_svg__h" />
      </g>
      <path
        d="M520 145h134c5.523 0 10 4.477 10 10v66c0 5.523-4.477 10-10 10H520c-5.523 0-10-4.477-10-10v-66c0-5.523 4.477-10 10-10z"
        fill="#A7A7EE"
      />
      <g>
        <use fill="#B0B0F9" xlinkHref="#mailbox_svg__i" />
        <use fill="url(#mailbox_svg__j)" xlinkHref="#mailbox_svg__i" />
      </g>
      <g transform="translate(210 20)">
        <mask id="mailbox_svg__m" fill="#fff">
          <use xlinkHref="#mailbox_svg__k" />
        </mask>
        <use fill="#464D89" xlinkHref="#mailbox_svg__k" />
        <path
          d="M165 0c52.467 0 95 45.39 95 101.38V296H70V101.38C70 45.39 112.533 0 165 0z"
          fill="url(#mailbox_svg__l)"
          mask="url(#mailbox_svg__m)"
        />
      </g>
      <g transform="translate(121 109)">
        <mask id="mailbox_svg__p" fill="#fff">
          <use xlinkHref="#mailbox_svg__n" />
        </mask>
        <use fill="#E6E6EF" xlinkHref="#mailbox_svg__n" />
        <use fill="url(#mailbox_svg__o)" xlinkHref="#mailbox_svg__n" />
        <g mask="url(#mailbox_svg__p)">
          <use fill="#F7F7F8" xlinkHref="#mailbox_svg__q" />
          <use fill="url(#mailbox_svg__r)" xlinkHref="#mailbox_svg__q" />
        </g>
        <path
          d="M0 67.64V10h220v57.64C151.498 105.213 114.831 124 110 124c-4.831 0-41.498-18.787-110-56.36z"
          fill="url(#mailbox_svg__s)"
          mask="url(#mailbox_svg__p)"
        />
        <g mask="url(#mailbox_svg__p)">
          <use fill="#F7F7F8" xlinkHref="#mailbox_svg__t" />
          <use fill="url(#mailbox_svg__u)" xlinkHref="#mailbox_svg__t" />
        </g>
      </g>
      <g transform="translate(56)" fill="#FEEC80">
        <circle cx={159} cy={39} r={13} />
        <circle cx={74} cy={62} r={13} />
        <circle cx={92} cy={5} r={5} />
        <circle cx={7.5} cy={46.5} r={7.5} />
        <circle cx={211.5} cy={74.5} r={7.5} />
        <circle cx={321} cy={183} r={13} />
        <circle cx={305} cy={223} r={5} />
        <circle cx={235} cy={36} r={5} />
        <circle cx={303} cy={79} r={13} />
      </g>
    </g>
  </svg>
);

export default SvgMailbox;

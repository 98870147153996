import PasteLinkify from 'slate-paste-linkify';
import styled from '@emotion/styled';
import RenderInline from '../helpers/RenderInline';

const isLinkActive = (editor, value) =>
  value.inlines.some(inline => inline.type === 'link');
const getLink = (editor, value) =>
  value.inlines.filter(inline => inline.type === 'link').first();
const createLink = data => ({ type: 'link', data });
const hasMultiBlocks = value => value.blocks.size > 1;

const unwrapLink = editor => editor.unwrapInline('link');

const updateLink = (editor, { href, text }) => {
  const { value } = editor;
  const { selection } = value;

  if (selection.isCollapsed) {
    editor.moveAnchorTo(0).moveFocusTo(text && text.length);
  }

  editor.insertText(text).setInlines({
    type: 'link',
    data: { href, text },
  });
};

const wrapLink = (editor, href) => {
  const { value } = editor;
  const { selection } = value;

  if (isLinkActive(editor, value)) {
    unwrapLink(editor);
  } else if (selection.isExpanded && !hasMultiBlocks(value)) {
    editor.wrapInline(createLink({ href })).moveToEnd();
  } else if (hasMultiBlocks(value)) {
    console.info('slate editor has multiple blocks on selection');
  } else if (selection.isCollapsed && !isLinkActive(editor, value)) {
    console.info('slate selection collapsed, w/o links in selection ');
  }
};

const Link = styled('a')`
  text-decoration: none;
  color: #54acdc;
`;

const renderFn = (attributes, children, node) => {
  const { data } = node;
  const href = data.get('href');
  return (
    <Link {...attributes} href={href}>
      {children}
    </Link>
  );
};

function Links(options) {
  const { type } = options;
  return [
    {
      commands: {
        wrapLink,
        unwrapLink,
        updateLink,
      },
      queries: {
        isLinkActive,
        getLink,
      },
    },
    RenderInline({
      type,
      renderFn,
    }),
    PasteLinkify(),
  ];
}

export default Links;

function RenderInline(options) {
  const { type, renderFn } = options;

  return {
    renderInline(props, editor, next) {
      const { attributes, children, node } = props;
      if (node.type === type) {
        return renderFn(attributes, children, node);
      }
      return next();
    },
  };
}

export default RenderInline;

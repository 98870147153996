/* eslint consistent-return: 0 */
/* eslint default-case: 0 */

import Html from 'slate-html-serializer';

const BLOCK_TAGS = {
  p: 'paragraph',
  li: 'list_item',
  ul: 'ul_list',
  ol: 'ol_list',
};

const MARK_TAGS = {
  strong: 'bold',
  em: 'italic',
  u: 'underlined',
  s: 'strikethrough',
};

export const RULES = [
  {
    deserialize(el, next) {
      const block = BLOCK_TAGS[el.tagName.toLowerCase()];

      if (block) {
        return {
          object: 'block',
          type: block,
          nodes: next(el.childNodes),
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === 'block') {
        switch (obj.type) {
          case 'paragraph':
            return <p className={obj.data.get('className')}>{children}</p>;
          case 'list_item':
            return <li>{children}</li>;
          case 'ul_list':
            return <ul>{children}</ul>;
          case 'ol_list':
            return <ol>{children}</ol>;
          default:
            return <p className={obj.data.get('className')}>{children}</p>;
        }
      }
    },
  },
  {
    deserialize(el, next) {
      const mark = MARK_TAGS[el.tagName.toLowerCase()];

      if (mark) {
        return {
          object: 'mark',
          type: mark,
          nodes: next(el.childNodes),
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === 'mark') {
        switch (obj.type) {
          case 'bold':
            return <strong>{children}</strong>;
          case 'italic':
            return <em>{children}</em>;
          case 'underlined':
            return <u>{children}</u>;
          case 'strikethrough':
            return <s>{children}</s>;
        }
      }
    },
  },
  // {
  //   // Special case for code blocks, which need to grab the nested childNodes.
  //   deserialize(el, next) {
  //     if (el.tagName.toLowerCase() === 'pre') {
  //       const code = el.childNodes[0];
  //       const childNodes =
  //         code && code.tagName.toLowerCase() === 'code'
  //           ? code.childNodes
  //           : el.childNodes;
  //
  //       return {
  //         object: 'block',
  //         type: 'code',
  //         nodes: next(childNodes),
  //       };
  //     }
  //   },
  // },
  // {
  //   // Special case for images, to grab their src.
  //   deserialize(el, next) {
  //     if (el.tagName.toLowerCase() === 'img') {
  //       return {
  //         object: 'block',
  //         type: 'image',
  //         nodes: next(el.childNodes),
  //         data: {
  //           src: el.getAttribute('src'),
  //         },
  //       };
  //     }
  //   },
  // },
  {
    // Special case for links, to grab their href.
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'a') {
        return {
          object: 'inline',
          type: 'link',
          nodes: next(el.childNodes),
          data: {
            href: el.getAttribute('href'),
          },
        };
      }
    },
    serialize(obj, children) {
      if (obj.object === 'inline' && obj.type === 'link') {
        return <a href={obj.data.get('href')}>{children}</a>;
      }
    },
  },
];

// Look into jsdom.fragment if needed for server side parsing: https://docs.slatejs.org/other-packages/index
const html = new Html({ rules: RULES });

export default html;

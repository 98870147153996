import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PoseGroup } from 'react-pose';
import cn from 'classnames';
import { graphql, withApollo } from '@apollo/react-hoc';
import compose from 'lodash.flowright';
import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import * as Sentry from '@sentry/browser';
import { Segment } from '../utils/segment';
import Section from './Section';
import PlanSection from './PlanSection';
import BillingSection from './BillingSection';
import FormInput from './FormInput';
import Confirmation from './Confirmation';
import { Form, FormSection, ConfScreen } from './Animations';
import { COMMON_STRINGS } from '../constants';
import { a11yHandleEnterKeyPress } from '../utils/a11y';
import { countDigitsInPhoneString } from '../utils/numbers';
import formStyleOverride from '../styles/GroupInfoForm.scss';
import styles from '../styles/ConversionPage.scss';
import pageStyles from '../styles/FormPage.scss';
import color from '../styles/themes/solid.scss';

const GroupSubscriptionForm = ({
  phone,
  email,
  name,
  nickname,
  groupname,
  validation,
  checkValidation,
  onInput,
  onPhoneInput,
  onSubmit,
}) => {
  const [showDetailFields, setShowDetailFields] = useState(false);

  const [plan, setPlan] = useState('yearly');

  const handleShow = () => setShowDetailFields(true);

  const handlePlanChange = value => setPlan(value);

  const showDetailsButton =
    !showDetailFields && countDigitsInPhoneString(phone) >= 10;

  return (
    <>
      <FormSection>
        <Section
          name={"hi there, let's finish setting you up"}
          description={COMMON_STRINGS.PHONE_DESCRIPTION}
        >
          <div className={pageStyles.form}>
            <div className={pageStyles.phone}>
              <FormInput
                name="phone"
                autofocus
                isPhone
                showLabel={phone !== ''}
                showValidation={validation.phone}
                value={phone}
                label="phone number"
                validation="required"
                placeholder="what's your phone number?"
                onInputChange={onPhoneInput}
                overrideStyle={formStyleOverride}
                theme="SOLID"
              >
                {showDetailsButton && (
                  <p className={styles.bottomlabel}>
                    sweet!{' '}
                    <span
                      className={styles.infoButton}
                      onClick={handleShow}
                      role="button"
                      onKeyPress={a11yHandleEnterKeyPress(handleShow)}
                      tabIndex={0}
                    >
                      want to change your email or name?
                    </span>
                  </p>
                )}
              </FormInput>
            </div>
            {showDetailFields && (
              <div className={styles.extraDetails}>
                <div className={pageStyles.names}>
                  <div className={pageStyles.fullname}>
                    <FormInput
                      name="name"
                      showLabel={name !== ''}
                      showValidation={validation.name}
                      value={name}
                      label="full name"
                      validation="required"
                      placeholder="what's your name?"
                      onInputChange={onInput}
                      overrideStyle={formStyleOverride}
                      theme="SOLID"
                    />
                  </div>
                  <div className={pageStyles.nickname}>
                    <FormInput
                      name="nickname"
                      showLabel={nickname !== ''}
                      showValidation={validation.nickname}
                      value={nickname}
                      label="nickname"
                      validation="required"
                      placeholder="nickname?"
                      onInputChange={onInput}
                      overrideStyle={formStyleOverride}
                      theme="SOLID"
                    />
                  </div>
                </div>
                <div className={pageStyles.email}>
                  <FormInput
                    name="email"
                    showLabel={email !== ''}
                    showValidation={validation.email}
                    value={email}
                    label="email"
                    validation="required"
                    placeholder="what's your email?"
                    onInputChange={onInput}
                    overrideStyle={formStyleOverride}
                    theme="SOLID"
                  />
                </div>
              </div>
            )}
          </div>
        </Section>
      </FormSection>
      <FormSection>
        <Section
          name="choose a group name"
          description="pick something memorable like 'the homies' or 'nyc friends' or 'ride or dies' "
        >
          <div className={pageStyles.group}>
            <FormInput
              name="groupname"
              showLabel={groupname !== ''}
              showValidation={validation.groupname}
              value={groupname}
              label="group name"
              validation="required"
              placeholder="name your group"
              onInputChange={onInput}
              overrideStyle={formStyleOverride}
              theme="SOLID"
            >
              {groupname !== '' && (
                <p className={cn(styles.bottomlabel, color.bottomlabel)}>
                  your friends will see: {nickname} invited you to join the
                  group &apos;
                  {groupname}
                  &apos;
                </p>
              )}
            </FormInput>
          </div>
        </Section>
      </FormSection>
      <FormSection>
        <PlanSection selected={plan} onPlanChange={handlePlanChange} />
      </FormSection>
      <FormSection>
        <BillingSection
          validation={checkValidation}
          onSubmit={onSubmit}
          monthly={plan === 'monthly'}
          email={email}
          groupname={groupname}
        />
      </FormSection>
    </>
  );
};

GroupSubscriptionForm.propTypes = {
  phone: PropTypes.string,
  email: PropTypes.string,
  name: PropTypes.string,
  nickname: PropTypes.string,
  groupname: PropTypes.string,
  validation: PropTypes.shape({
    phone: PropTypes.bool,
    email: PropTypes.bool,
    name: PropTypes.bool,
    nickname: PropTypes.bool,
    groupname: PropTypes.bool,
  }),
  checkValidation: PropTypes.func,
  onInput: PropTypes.func,
  onPhoneInput: PropTypes.func,
  onSubmit: PropTypes.func,
};

const USER_QUERY = gql`
  query UserQuery($userId: ID!) {
    user(userId: $userId) {
      id
      name
      nickname
      email
      phone
    }
  }
`;

const Conversion = ({ userId, initialGroupName, client, updateUser }) => {
  const [form, setForm] = useState({
    email: '',
    name: '',
    nickname: '',
    phone: '',
    groupname: initialGroupName || '',
  });

  const [validation, setValidation] = useState({
    email: false,
    name: false,
    nickname: false,
    phone: false,
    groupname: false,
  });

  const [subscribed, setSubscribed] = useState(false);
  const [onboarding, setOnboarding] = useState({
    to: '',
    from: '',
    subject: '',
    inviteUrl: '',
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const {
          data: { user },
        } = await client.query({
          query: USER_QUERY,
          variables: { userId },
        });

        if (user) {
          const { id, name, nickname, email, phone } = user;
          setForm({
            name,
            nickname,
            email,
            phone: phone || '',
            groupname: initialGroupName || '',
          });

          Segment.identify(id, {
            name,
            email,
          });
        }
      } catch (error) {
        console.error(`Error querying for a user with id: ${userId}`, error);
        Sentry.captureException(error);
      }
    };

    fetchInitialData();
  }, [client, initialGroupName, userId]);

  const handleInputChange = e => {
    if (validation[e.target.name]) {
      setValidation({
        ...validation,
        [e.target.name]: false,
      });
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneInputChange = value => {
    console.log('phone input changed getting called', value);
    if (validation.phone) {
      setValidation({
        ...validation,
        phone: false,
      });
    }

    setForm({
      ...form,
      phone: value || '',
    });
  };

  const validationHandled = () => {
    const { name, nickname, email, phone, groupname } = form;
    const missingField = !name || !nickname || !email || !phone || !groupname;

    if (missingField) {
      setValidation({
        name: !name,
        nickname: !nickname,
        email: !email,
        phone: !phone,
        groupname: !groupname,
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async responseData => {
    // handle the post submit message
    const {
      subscribe: {
        inviteUrl,
        email: { to, from, subject },
        subscribe: {
          group: { groupId, name: groupname },
        },
      },
    } = responseData;

    Segment.group(groupId, {
      name: groupname,
    });

    const { name, nickname, email, phone } = form;

    setSubscribed(true);
    setOnboarding({
      to,
      from,
      subject,
      inviteUrl,
    });

    await updateUser({
      variables: {
        userId,
        name,
        nickname,
        email,
        phone,
      },
    });
  };

  const { name, nickname, email, phone, groupname } = form;
  const { to, from, subject, inviteUrl } = onboarding;

  return (
    <div className={pageStyles.formsections}>
      <PoseGroup>
        {!subscribed && (
          <Form key="form" className="pose-form">
            <GroupSubscriptionForm
              key="form-screen"
              name={name}
              nickname={nickname}
              email={email}
              phone={phone}
              groupname={groupname}
              validation={validation}
              checkValidation={validationHandled}
              onInput={handleInputChange}
              onPhoneInput={handlePhoneInputChange}
              onSubmit={handleSubmit}
            />
          </Form>
        )}
        {subscribed && (
          <ConfScreen key="conf-screen" className="pose-confirmation">
            <Confirmation
              groupname={groupname}
              to={to}
              from={from}
              subject={subject}
              inviteUrl={inviteUrl}
              created
            />
          </ConfScreen>
        )}
      </PoseGroup>
    </div>
  );
};

Conversion.propTypes = {
  userId: PropTypes.string.isRequired,
  initialGroupName: PropTypes.string,
  client: PropTypes.instanceOf(ApolloClient),
  updateUser: PropTypes.func,
};

const UPDATE_USER = gql`
  mutation UpdateUser(
    $userId: ID!
    $name: String!
    $nickname: String!
    $email: String!
    $phone: String!
  ) {
    updateUser(
      userId: $userId
      name: $name
      nickname: $nickname
      email: $email
      phone: $phone
    ) {
      id
      name
      nickname
      email
      phone
    }
  }
`;

export default compose(
  withApollo,
  graphql(UPDATE_USER, { name: 'updateUser' }),
)(Conversion);

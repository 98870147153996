import React, { useEffect } from 'react';
import { Segment } from '../utils/segment';
import '../styles/NotFoundPage.scss';

const NotFoundPage = () => {
  useEffect(() => {
    Segment.page('Not Found');
  });
  return <p>Not Found!!</p>;
};

export default NotFoundPage;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../styles/FormPage.scss';
import color from '../styles/themes/solid.scss';

function Section(props) {
  const { name, description, descriptionSecondary, children, lowspace } = props;

  const titleStyle = lowspace
    ? cn(styles.title, styles.lowspace)
    : styles.title;

  return (
    <section className={color.section}>
      <div className={titleStyle}>
        <h2 className={color.title}>{name}</h2>
        <div className={color.text}>
          <p>{description}</p>
          {descriptionSecondary !== '' && (
            <p>
              <br />
              {descriptionSecondary}
            </p>
          )}
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </section>
  );
}

Section.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  descriptionSecondary: PropTypes.string,
  children: PropTypes.node,
  lowspace: PropTypes.bool,
};

Section.defaultProps = {
  descriptionSecondary: '',
  lowspace: false,
};

export default Section;

import dateformat from 'dateformat';
import addDays from 'date-fns/addDays';
import isYesterday from 'date-fns/isYesterday';
import format from 'date-fns/format';

export function getCancelDate(start, trialdays) {
  return format(addDays(start, trialdays), 'M/d/yy');
}

export function formatDuedate(due) {
  const date = new Date(due);
  return format(date, 'EEEE, MMM Mo').toLowerCase();
}

const msecs = {
  sec: 1000,
  ten_sec: 1000 * 10,
  min: 1000 * 60,
  hour: 1000 * 60 * 60,
  half_day: 1000 * 60 * 60 * 12,
  day: 1000 * 60 * 60 * 24,
  four_days: 1000 * 60 * 60 * 24 * 4,
  week: 1000 * 60 * 60 * 24 * 7,
};

export function soon({ due, current = new Date() }) {
  const diff = due - current;
  return diff < msecs.day;
}

const TZ = {
  ABBREV: {
    'America/Los_Angeles': 'PST',
    'America/New_York': 'EST',
  },
};

export const getTimezoneAbbrev = tz => TZ.ABBREV[tz] || '';

export function timeLeft({ due, tz, current = new Date(), short = false }) {
  const diff = due - current;

  if (diff < 0) {
    return `now`;
  }

  if (diff < msecs.min) {
    return `in ${Math.floor(diff / msecs.sec)} seconds`;
  }

  if (diff < msecs.hour) {
    return `in ${Math.floor(diff / msecs.min)} ${short ? 'mins' : 'minutes'}`;
  }

  const abbrev = getTimezoneAbbrev(tz);

  if (diff < msecs.day) {
    return `at midnight tonight ${abbrev}`;
  }

  if (diff < msecs.week) {
    return `this sunday${short ? '' : ` at midnight ${abbrev}`}`;
  }

  return `in ${Math.floor(diff / msecs.day)} days${
    short ? '' : ` at midnight ${abbrev}`
  }`;
}

export function humanizeDate({ date, currentDate = new Date() }) {
  const diff = currentDate - date;

  if (diff < msecs.ten_sec) {
    return 'just now';
  }
  if (diff < msecs.min) {
    return '1 min ago';
  }
  if (diff < msecs.hour) {
    const mins = Math.floor(diff / msecs.min);
    return mins === 1 ? '1 min ago' : mins + ' mins ago';
  }
  if (diff < msecs.half_day) {
    const hrs = Math.floor(diff / msecs.hour);
    return hrs === 1 ? '1 hr ago' : hrs + ' hrs ago';
  }
  if (diff < msecs.day) {
    const time = dateformat(date, 'h:MM TT');
    return isYesterday(date) ? 'yesterday at ' + time : 'at ' + time;
  }
  if (diff < msecs.four_days) {
    const day = dateformat(date, 'dddd');
    const time = dateformat(date, 'h:MM TT');
    return 'on ' + day + ' at ' + time;
  }
  const d = dateformat(date, 'dddd, mmmm dS');
  return 'on ' + d;
}

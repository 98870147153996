import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Segment } from '../utils/segment';
import { useAuthContext } from './context/AuthProvider';
import ErrorBoundary from './ErrorBoundary';
import HandleError from './HandleError';
import Nav from './Nav';
import MagicLinkRequest from './MagicLinkRequest';
import HeaderTertiary from './HeaderTertiary';
import PageLoader from './PageLoader';
import styles from '../styles/MagicLinkPage.scss';
import grid from '../styles/PageGrid.scss';

const Message = ({ label }) => (
  <div className={styles.container}>
    <div className={styles.e}>
      <HeaderTertiary label={label} />
    </div>
  </div>
);

Message.propTypes = {
  label: PropTypes.string,
};

const MagicLinkPage = ({ location, match }) => {
  const {
    state: { from } = { from: null }, // set a back up if state doesn't exist
  } = location;
  const { token, after } = match.params;
  const [redirect, setRedirect] = useState({
    path: null,
    loading: !!token,
  });
  const [error, setError] = useState(
    from ? { message: 'you need to be logged in to view this page' } : null,
  );
  const [referrer] = useState(from);

  const { login } = useAuthContext();

  useEffect(() => {
    Segment.page('Magic Link');

    const followLink = async () => {
      try {
        const { groups } = await login(token);
        let redir;
        if (after) {
          redir = decodeURIComponent(after);
        } else {
          redir = groups.length >= 2 ? '/group' : '/snippets';
        }
        setRedirect({
          path: redir,
          loading: false,
        });
      } catch (e) {
        console.error('Error with linkLogin: ', e);
        Sentry.captureException(e);
        setError(e);
      }
    };

    if (token) {
      followLink();
    }
  }, [after, token, login]);

  const onSubmitRequest = async () => {
    setError(null);
    setRedirect({
      loading: false,
      path: null,
    });
  };

  const refuri = referrer ? encodeURIComponent(referrer) : undefined;

  if (redirect.loading && !error) {
    return (
      <div className={styles.loading}>
        <PageLoader label="following the magic link to log you in" />
      </div>
    );
  }

  if (redirect.path) return <Redirect to={redirect.path} />; // send to a new route

  return (
    <ErrorBoundary>
      <div className={grid.container}>
        <Nav showAuthLinks />
        <div className={styles.content}>
          {error && (
            <HandleError error={error}>
              {friendlyMsg => <Message label={friendlyMsg} />}
            </HandleError>
          )}
          <MagicLinkRequest onSubmit={onSubmitRequest} refuri={refuri} />
        </div>
      </div>
    </ErrorBoundary>
  );
};

MagicLinkPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
      after: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.string,
    }),
  }),
};

export default MagicLinkPage;

import React from 'react';

const SvgRobot = props => (
  <svg width={500} viewBox="0 0 326 697" {...props}>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="robot_svg__b">
        <stop stopColor="#222169" stopOpacity={0} offset="0%" />
        <stop stopOpacity={0.1} offset="100%" />
      </linearGradient>
      <rect id="robot_svg__a" x={0} y={36} width={67} height={157} rx={33.5} />
      <rect
        id="robot_svg__c"
        x={223}
        y={36}
        width={67}
        height={157}
        rx={33.5}
      />
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="robot_svg__e">
        <stop stopColor="#403F9F" stopOpacity={0} offset="0%" />
        <stop stopOpacity={0.2} offset="100%" />
      </linearGradient>
      <path
        d="M69.87 0h150.278C258.726 0 290 31.34 290 70c0 8.106-1.405 16.15-4.152 23.775l-46.193 128.19C225.375 261.591 187.842 288 145.799 288h-1.579c-42.043 0-79.576-26.408-93.856-66.036L4.171 93.775C-8.93 57.413 9.861 17.292 46.146 4.161A69.72 69.72 0 0 1 69.871 0z"
        id="robot_svg__d"
      />
      <path
        d="M146.032 101.128l26.181-33.367a1 1 0 0 1 1.787.617v116.968a1 1 0 0 1-1.92.393l-26.4-61.688a1 1 0 0 0-1.719-.208L117.8 158.576a1 1 0 0 1-1.799-.602V41a1 1 0 0 1 1.916-.401l26.413 60.313a1 1 0 0 0 1.703.216z"
        id="robot_svg__f"
      />
      <path id="robot_svg__g" d="M125 260h76v30h-76z" />
      <linearGradient x1="50%" y1="50%" x2="100%" y2="50%" id="robot_svg__i">
        <stop stopOpacity={0.5} offset="0%" />
        <stop stopColor="#222169" stopOpacity={0} offset="100%" />
      </linearGradient>
      <circle id="robot_svg__h" cx={286} cy={167} r={40} />
      <linearGradient x1="50%" y1="50%" x2="0%" y2="50%" id="robot_svg__k">
        <stop stopOpacity={0.5} offset="0%" />
        <stop stopColor="#222169" stopOpacity={0} offset="100%" />
      </linearGradient>
      <circle id="robot_svg__j" cx={40} cy={167} r={40} />
      <path
        d="M175.877 70a376.131 376.131 0 0 0-25.754 0l6.529-41.494C151.54 26.123 148 20.951 148 14.954 148 6.695 154.716 0 163 0c8.284 0 15 6.695 15 14.954 0 5.997-3.54 11.169-8.652 13.552L175.877 70z"
        id="robot_svg__l"
      />
      <path
        d="M301 126c0 76.275 0 138-138 138S25 202.275 25 126c0-76.007 276-76.007 276 0z"
        id="robot_svg__m"
      />
      <linearGradient
        x1="50%"
        y1="-24.809%"
        x2="50%"
        y2="25.162%"
        id="robot_svg__o"
      >
        <stop stopColor="#D6DFEA" offset="0%" />
        <stop stopColor="#222169" offset="100%" />
      </linearGradient>
      <path
        d="M22 138.939C53.863 128.979 101.197 124 164 124s110.137 4.98 142 14.939v65.73C274.137 212.223 226.803 216 164 216s-110.137-3.777-142-11.33v-65.731z"
        id="robot_svg__p"
      />
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="robot_svg__r">
        <stop stopColor="#C74D75" stopOpacity={0} offset="0%" />
        <stop stopColor="#AF3B6E" stopOpacity={0.289} offset="100%" />
      </linearGradient>
      <circle id="robot_svg__q" cx={164} cy={170} r={15} />
      <circle id="robot_svg__s" cx={224} cy={170} r={15} />
      <circle id="robot_svg__t" cx={104} cy={170} r={15} />
    </defs>
    <g fill="none" fillRule="evenodd">
      <ellipse fill="#F0F0F6" cx={163} cy={677} rx={145} ry={20} />
      <g transform="translate(18 289)">
        <use fill="#222169" xlinkHref="#robot_svg__a" />
        <use fill="url(#robot_svg__b)" xlinkHref="#robot_svg__a" />
      </g>
      <g transform="translate(18 289)">
        <use fill="#222169" xlinkHref="#robot_svg__c" />
        <use fill="url(#robot_svg__b)" xlinkHref="#robot_svg__c" />
      </g>
      <g transform="translate(18 289)">
        <use fill="#403F9F" xlinkHref="#robot_svg__d" />
        <use fill="url(#robot_svg__e)" xlinkHref="#robot_svg__d" />
      </g>
      <g transform="translate(18 289)">
        <use fill="#222169" xlinkHref="#robot_svg__f" />
        <use fill="url(#robot_svg__b)" xlinkHref="#robot_svg__f" />
      </g>
      <g>
        <use fill="#222169" xlinkHref="#robot_svg__g" />
        <use fill="url(#robot_svg__b)" xlinkHref="#robot_svg__g" />
        <use fill="#403F9F" xlinkHref="#robot_svg__h" />
        <use fill="url(#robot_svg__e)" xlinkHref="#robot_svg__h" />
        <use fill="url(#robot_svg__i)" xlinkHref="#robot_svg__h" />
        <use fill="#403F9F" xlinkHref="#robot_svg__j" />
        <use fill="url(#robot_svg__e)" xlinkHref="#robot_svg__j" />
        <use fill="url(#robot_svg__k)" xlinkHref="#robot_svg__j" />
        <use fill="#222169" xlinkHref="#robot_svg__l" />
        <use fill="url(#robot_svg__b)" xlinkHref="#robot_svg__l" />
        <mask id="robot_svg__n" fill="#fff">
          <use xlinkHref="#robot_svg__m" />
        </mask>
        <use fill="#403F9F" xlinkHref="#robot_svg__m" />
        <use fill="url(#robot_svg__e)" xlinkHref="#robot_svg__m" />
        <circle
          fill="#201F61"
          mask="url(#robot_svg__n)"
          cx={163}
          cy={55}
          r={43}
        />
        <path
          d="M22 130.563C53.863 119.52 101.197 114 164 114s110.137 5.52 142 16.563v72.875C274.137 211.813 226.803 216 164 216s-110.137-4.188-142-12.563v-72.875z"
          fill="url(#robot_svg__o)"
          opacity={0.2}
          mask="url(#robot_svg__n)"
        />
        <g mask="url(#robot_svg__n)">
          <use fill="#222169" xlinkHref="#robot_svg__p" />
          <use fill="url(#robot_svg__b)" xlinkHref="#robot_svg__p" />
        </g>
        <g mask="url(#robot_svg__n)">
          <use fill="#FF6663" xlinkHref="#robot_svg__q" />
          <use fill="url(#robot_svg__r)" xlinkHref="#robot_svg__q" />
        </g>
        <g mask="url(#robot_svg__n)">
          <use fill="#FF6663" xlinkHref="#robot_svg__s" />
          <use fill="url(#robot_svg__r)" xlinkHref="#robot_svg__s" />
        </g>
        <g mask="url(#robot_svg__n)">
          <use fill="#FF6663" xlinkHref="#robot_svg__t" />
          <use fill="url(#robot_svg__r)" xlinkHref="#robot_svg__t" />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgRobot;

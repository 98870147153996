import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Card from 'react-rainbow-components/components/Card';
import Avatar from 'react-rainbow-components/components/Avatar';
import Input from 'react-rainbow-components/components/Input';
import ButtonIcon from 'react-rainbow-components/components/ButtonIcon';
import Button from 'react-rainbow-components/components/Button';
import Modal from 'react-rainbow-components/components/Modal';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faPaperPlane,
  faCreditCard,
} from '@fortawesome/free-regular-svg-icons';
import {
  faPencilAlt,
  faPause,
  faSave,
  faCreditCard as faCreditCardSolid,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCcVisa,
  faCcAmex,
  faCcDiscover,
  faCcMastercard,
} from '@fortawesome/free-brands-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { soon, timeLeft } from '../utils/date';
import {
  PLAN_INFO_QUERY,
  CARD_INFO_QUERY,
  USER_HAS_POST_QUERY,
} from './SharedGql';
import { useAuthContext } from './context/AuthProvider';
import PageLoader from './PageLoader';
import UpdateCard from './UpdateCard';
import styles from '../styles/Group.scss';
import appStyles from '../styles/App.scss';

const GROUP_QUERY = gql`
  query GroupQuery($groupId: ID!) {
    group(groupId: $groupId) {
      id
      tz
      name
      admin {
        id
        name
        nickname
        email
      }
      frequency
      invites {
        id
        accepted
        email
      }
      members {
        id
        name
        nickname
        email
      }
      current {
        id
        due
        tz
      }
      subscription {
        id
        paused
      }
      inviteCode
    }
  }
`;

const GroupSectionCard = ({ backgroundColor, children }) => (
  <Card className={styles.card} style={{ backgroundColor }}>
    {children}
  </Card>
);

GroupSectionCard.defaultProps = {
  backgroundColor: '#fff',
};

GroupSectionCard.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
};

const UPDATE_NAME = gql`
  mutation UPDATE_NAME_MUTATION($groupId: ID!, $name: String!) {
    updateGroupname(groupId: $groupId, name: $name) {
      id
      name
    }
  }
`;

const GroupSettings = ({ groupname, groupId }) => {
  const inputEl = useRef(null);
  const [value, setValue] = useState(groupname);
  const [errorMsg, setErrorMsg] = useState('');
  const [editable, setEditable] = useState(false);
  const [updateName] = useMutation(UPDATE_NAME, {
    refetchQueries: [{ query: GROUP_QUERY, variables: { groupId } }],
  });

  const notify = () =>
    toast('we updated the group name.', {
      autoClose: 3000,
      progressClassName: appStyles.toastNoProgress,
    });

  const toggleEditable = async () => {
    if (editable) {
      if (value === '') {
        setErrorMsg("y'all need a name.");
      } else {
        // save current state of groupname
        setEditable(false);
        await updateName({
          variables: {
            groupId,
            name: value,
          },
        });
        notify();
      }
    } else {
      setEditable(true);
      inputEl.current.focus();
    }
  };

  const onEnter = event => {
    if (event.key === 'Enter') {
      toggleEditable();
    }
  };

  const onChange = event => {
    if (errorMsg && event.target.value) {
      setErrorMsg('');
    }

    setValue(event.target.value);
  };

  const onClick = () => {
    if (!editable) {
      setEditable(true);
    }
  };

  return (
    <Card className={styles.groupSettings}>
      <div className={styles.title}>
        <h2>group</h2>
        <ButtonIcon
          variant="border"
          size="small"
          icon={<FontAwesomeIcon icon={!editable ? faPencilAlt : faSave} />}
          style={{ marginTop: '-5px' }}
          onClick={toggleEditable}
        />
      </div>
      <Input
        readOnly={!editable}
        value={value}
        error={errorMsg}
        label="name"
        onChange={onChange}
        onKeyDown={onEnter}
        onClick={onClick}
        ref={inputEl}
      />
    </Card>
  );
};

GroupSettings.propTypes = {
  groupname: PropTypes.string,
  groupId: PropTypes.string,
};

const PlanSection = ({ paused, planInfo }) => {
  const {
    status,
    price,
    frequency,
    nextBillDate,
    // cancelAtPeriodEnd,
    trialEndDate,
    card,
  } = planInfo;

  if (paused) {
    return (
      <div className={styles.item}>
        <span className={styles.infolabel}>plan</span>
        <span className={styles.infodetail}>paused</span>
      </div>
    );
  }

  const trial = status === 'trialing';
  const subscriptionLabel = `${price}/${frequency}`;

  return (
    <>
      {trial && (
        <div className={styles.item}>
          <span className={styles.infolabel}>free trial ends</span>
          <span className={styles.infodetail}>{trialEndDate}</span>
        </div>
      )}
      <div className={styles.item}>
        <span className={styles.infolabel}>plan</span>
        <span className={styles.infodetail}>{subscriptionLabel}</span>
      </div>
      {!trial && (
        <>
          <div className={styles.item}>
            <span className={styles.infolabel}>next charged on</span>
            <span className={styles.infodetail}>{nextBillDate}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.infolabel}>card</span>
            <span
              className={styles.infodetail}
            >{`${card.brand} ${card.last4}`}</span>
          </div>
        </>
      )}
    </>
  );
};

PlanSection.propTypes = {
  paused: PropTypes.bool,
  planInfo: PropTypes.shape({
    status: PropTypes.string,
    price: PropTypes.string,
    frequency: PropTypes.string,
    nextBillDate: PropTypes.string,
    // cancelAtPeriodEnd: PropTypes.string,
    trialEndDate: PropTypes.string,
    card: PropTypes.shape({
      brand: PropTypes.string,
      last4: PropTypes.string,
    }),
  }),
};

const UNPAUSE_MUTATION = gql`
  mutation UNPAUSE_MUTATION($subscribeId: ID!) {
    unpause(subscribeId: $subscribeId) {
      paused
    }
  }
`;

const PAUSE_MUTATION = gql`
  mutation PAUSE_MUTATION($subscribeId: ID!) {
    pause(subscribeId: $subscribeId) {
      paused
    }
  }
`;

const PauseSubscription = ({
  groupId,
  subscribeId,
  paused,
  trial,
  periodEnd,
}) => {
  const [isOpen, setOpen] = useState(false);

  const refetchQueries = [
    {
      query: PLAN_INFO_QUERY,
      variables: {
        subscribeId,
      },
    },
    {
      query: GROUP_QUERY,
      variables: {
        groupId,
      },
    },
  ];

  const [pause, { loading: pauseLoading }] = useMutation(PAUSE_MUTATION, {
    variables: {
      subscribeId,
    },
    refetchQueries,
  });

  const onPause = async () => {
    // actually pause the group, change the button
    await pause();
    setOpen(false);
  };

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  return (
    <>
      {!paused && (
        <ButtonIcon
          size="small"
          variant="border"
          title="pause subscription"
          icon={<FontAwesomeIcon icon={faPause} />}
          style={{
            marginRight: '4px',
            marginTop: '-5px',
          }}
          onClick={openModal}
        />
      )}
      <Modal
        isOpen={isOpen}
        title="are you sure?"
        onRequestClose={closeModal}
        size="small"
        className={styles.modal}
        footer={
          <div className={styles.footer}>
            <Button
              label="nevermind"
              variant="neutral"
              style={{
                marginRight: '12px',
              }}
              onClick={closeModal}
            />
            <Button
              label="pause"
              variant="destructive"
              isLoading={pauseLoading}
              onClick={onPause}
            />
          </div>
        }
      >
        <div className={styles.content}>
          if you pause the group subscription, we&apos;ll stop collecting
          snippets responses and sending them out for the group at the end of
          the {trial ? 'free trial' : 'current subscription period'} (
          {periodEnd}). <br />
          <br />
          you won&apos;t be charged from then on, and you can resubscribe at any
          time.
        </div>
      </Modal>
    </>
  );
};

PauseSubscription.propTypes = {
  groupId: PropTypes.string,
  subscribeId: PropTypes.string,
  paused: PropTypes.bool,
  trial: PropTypes.bool,
  periodEnd: PropTypes.string,
};

// For now just limit scope to change payment, but in the future
// let them change plan
const ChangePayment = ({ subscribeId, adminEmail }) => {
  const { loading, error, data = {} } = useQuery(CARD_INFO_QUERY, {
    variables: { subscribeId },
  });

  const [isOpen, setOpen] = useState(false);
  const [showChangeCard, setShowChangeCard] = useState(false);
  const [saveCardLoading, setSaveCardLoading] = useState(false);

  const closeModal = () => {
    setShowChangeCard(false);
    setOpen(false);
  };

  const getCardLogo = brand => {
    const validatedBrand = brand.toLowerCase();

    let icon;

    switch (validatedBrand) {
      case 'visa':
        icon = faCcVisa;
        break;
      case 'american express':
        icon = faCcAmex;
        break;
      case 'discover':
        icon = faCcDiscover;
        break;
      case 'mastercard':
        icon = faCcMastercard;
        break;
      default:
        icon = faCreditCardSolid;
        break;
    }

    return <FontAwesomeIcon size="2x" icon={icon} />;
  };

  const onChangeSuccess = () => setShowChangeCard(false);

  if (loading) {
    return (
      <ButtonIcon
        size="small"
        variant="border"
        title="change payment method"
        isLoading
        icon={<FontAwesomeIcon icon={faCreditCard} />}
      />
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const { card } = data;
  const { brand, last4, expMonth, expYear } = card;

  return (
    <>
      <ButtonIcon
        size="small"
        variant="border"
        title="change payment method"
        icon={<FontAwesomeIcon icon={faCreditCard} />}
        onClick={() => setOpen(true)}
      />
      <Modal
        title={!showChangeCard ? 'default card' : 'change card'}
        isOpen={isOpen}
        onRequestClose={closeModal}
        size="small"
        className={styles.modal}
        footer={
          <div className={styles.footer}>
            {!showChangeCard ? (
              <Button
                label="replace card"
                variant="neutral"
                style={{
                  marginRight: '12px',
                }}
                onClick={() => setShowChangeCard(true)}
              />
            ) : (
              <Button
                label="nevermind"
                variant="neutral"
                style={{
                  marginRight: '12px',
                }}
                onClick={() => setShowChangeCard(false)}
              />
            )}
            {!showChangeCard ? (
              <Button
                label="looks good"
                variant="success"
                onClick={closeModal}
              />
            ) : (
              <Button
                form="card-form"
                type="submit"
                label="save"
                loading={saveCardLoading}
                variant="success"
              />
            )}
          </div>
        }
      >
        <div className={styles.content}>
          {!showChangeCard ? (
            <div className={styles.creditCard}>
              <h4>{`•••• •••• •••• ${last4}`}</h4>
              <div className={styles.expiresLogo}>
                <h5>expires on {`${expMonth}/${expYear}`}</h5>
                {getCardLogo(brand)}
              </div>
            </div>
          ) : (
            <UpdateCard
              adminEmail={adminEmail}
              subscribeId={subscribeId}
              onSuccess={onChangeSuccess}
              isLoading={status => setSaveCardLoading(status)}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

ChangePayment.propTypes = {
  subscribeId: PropTypes.string,
  adminEmail: PropTypes.string,
};

const RestartSubscription = ({ groupId, subscribeId }) => {
  const [unpause, { loading }] = useMutation(UNPAUSE_MUTATION, {
    variables: {
      subscribeId,
    },
    refetchQueries: [
      {
        query: PLAN_INFO_QUERY,
        variables: {
          subscribeId,
        },
      },
      {
        query: GROUP_QUERY,
        variables: {
          groupId,
        },
      },
    ],
  });

  const onUnpause = async () => {
    await unpause();
  };

  return (
    <div className={styles.restartButton}>
      <Button
        label="restart subscription"
        variant="success"
        isLoading={loading}
        style={{
          marginTop: '12px',
        }}
        onClick={onUnpause}
      />
    </div>
  );
};

RestartSubscription.propTypes = {
  subscribeId: PropTypes.string,
  groupId: PropTypes.string,
};

const SubscriptionSettings = ({
  paused,
  subscribeId,
  groupId,
  adminName,
  adminEmail,
}) => {
  const { loading, error, data = {} } = useQuery(PLAN_INFO_QUERY, {
    variables: { subscribeId },
  });

  if (loading)
    return (
      <Card className={styles.subscriptionSettings}>
        <div className={styles.title}>
          <h2>looking it up...</h2>
        </div>
      </Card>
    );

  if (error)
    return (
      <Card className={styles.subscriptionSettings}>
        <div className={styles.title}>
          <h2>{error.message}</h2>
        </div>
      </Card>
    );

  const { plan } = data;

  return (
    <Card className={styles.subscriptionSettings}>
      <div className={styles.title}>
        <h2>subscription</h2>
        <div className={styles.actions}>
          <PauseSubscription
            groupId={groupId}
            subscribeId={subscribeId}
            paused={paused}
            periodEnd={plan.nextBillDate}
            trial={plan.status === 'trialing'}
          />
          <ChangePayment subscribeId={subscribeId} adminEmail={adminEmail} />
        </div>
      </div>

      <div className={styles.lineitems}>
        <div className={styles.item}>
          <span className={styles.infolabel}>admin</span>
          <span className={styles.infodetail}>{adminName.toLowerCase()}</span>
        </div>
        <PlanSection paused={paused} planInfo={plan} />
      </div>
      {paused && (
        <RestartSubscription subscribeId={subscribeId} groupId={groupId} />
      )}
    </Card>
  );
};

SubscriptionSettings.propTypes = {
  paused: PropTypes.bool,
  subscribeId: PropTypes.string,
  groupId: PropTypes.string,
  adminName: PropTypes.string,
  adminEmail: PropTypes.string,
};

const Settings = ({
  groupId,
  groupname,
  paused,
  adminName,
  adminEmail,
  subscribeId,
}) => {
  return (
    <div className={styles.settings}>
      <GroupSettings groupname={groupname} groupId={groupId} />
      <SubscriptionSettings
        paused={paused}
        adminName={adminName}
        adminEmail={adminEmail}
        groupId={groupId}
        subscribeId={subscribeId}
      />
    </div>
  );
};

Settings.propTypes = {
  groupId: PropTypes.string,
  groupname: PropTypes.string,
  paused: PropTypes.bool,
  adminName: PropTypes.string,
  adminEmail: PropTypes.string,
  subscribeId: PropTypes.string,
};

const pastelRainbow = ['#9A91AC', '#CAA7BD', '#FFB9C4', '#FFD3D4'];

const getInitials = name =>
  name
    .match(/\b\w/g)
    .join('')
    .toLowerCase();

const MembersSection = ({ members }) => {
  const memberAvatars = members.map((mem, idx) => (
    <Avatar
      key={mem.id}
      assistiveText={mem.nickname}
      initials={getInitials(mem.name)}
      title={mem.name}
      size="large"
      style={{
        backgroundColor: pastelRainbow[idx % pastelRainbow.length],
        margin: '4px 12px 4px 0',
      }}
    />
  ));

  return (
    <div className={styles.avatarSection}>
      <h2>group members</h2>
      <div className={styles.avatars}>{memberAvatars}</div>
    </div>
  );
};

MembersSection.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      nickname: PropTypes.string,
      email: PropTypes.string,
    }),
  ),
};

const firstInitial = str => str.charAt(0).toLowerCase();

const InvitesSection = ({ unacceptedInvites }) => {
  const inviteAvatars = unacceptedInvites.map(inv => (
    <Avatar
      key={inv.id}
      assistiveText={inv.email}
      initials={firstInitial(inv.email)}
      title={inv.email}
      style={{
        backgroundColor: '#f0003f',
        margin: '4px 8px 4px 0',
      }}
    />
  ));
  return (
    <div className={styles.avatarSection}>
      <h2>invited, but haven&apos;t joined yet</h2>
      <div className={styles.avatars}>{inviteAvatars}</div>
    </div>
  );
};

InvitesSection.propTypes = {
  unacceptedInvites: PropTypes.arrayOf(
    PropTypes.shape({
      accepted: PropTypes.bool,
      email: PropTypes.string,
    }),
  ),
};

const INVITE_MUTATION = gql`
  mutation INVITE_MUTATION($groupId: ID!, $email: String!) {
    invite(groupId: $groupId, email: $email) {
      id
      accepted
      email
    }
  }
`;

let endpoint;
if (process.env.NODE_ENV === 'development') {
  endpoint = `http://localhost:8080`;
} else if (process.env.BACKEND_STAGE === 'staging') {
  endpoint = `https://staging.dosnippets.com`;
} else {
  endpoint = `https://www.dosnippets.com`;
}

const SendInvite = ({ groupId, inviteCode }) => {
  const [email, setEmail] = useState('');
  const [check, setCheck] = useState(false);
  const sendEmailButton = useRef(null);

  const [invite, { error: inviteError }] = useMutation(INVITE_MUTATION, {
    variables: {
      groupId,
      email,
    },
    refetchQueries: [{ query: GROUP_QUERY, variables: { groupId } }],
  });

  let inviteToastId;
  // eslint-disable-next-line no-return-assign
  const notifyInvite = () => (inviteToastId = toast('sending invite...'));
  const updateNotifyInvite = msg => {
    toast.done(inviteToastId);
    toast.update(inviteToastId, {
      render: msg,
      progressClassName: appStyles.toastNoProgress,
      autoClose: 3000,
    });
  };

  const notifyCopy = () =>
    toast('copied!', {
      progressClassName: appStyles.toastNoProgress,
      autoClose: 2000,
    });

  const emailIsValid = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email,
  );

  const onChange = event => {
    setCheck(false);
    setEmail(event.target.value);
  };

  const onSubmit = async () => {
    sendEmailButton.current.blur();
    // notify();
    if (!emailIsValid) {
      setCheck(true);
    } else {
      try {
        notifyInvite();
        await invite();
        updateNotifyInvite(`🎉 invite sent.`);
        setEmail('');
      } catch (err) {
        updateNotifyInvite(`💀 unable to send invite. please try again`);
      }
    }
  };

  const onEnter = event => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const joinLink = `${endpoint}/join/${inviteCode}`;

  return (
    <div className={styles.sendInvite}>
      <h2>invite someone else</h2>
      <div className={styles.inputWithButton}>
        <Input
          label="send invite"
          value={email}
          onChange={onChange}
          onKeyDown={onEnter}
          iconPosition="right"
          isCentered
          placeholder="what's their email?"
          type="email"
          error={
            check && !emailIsValid && `that doesn't seem to be an email address`
          }
          style={{
            flexGrow: 1,
          }}
        />
        <ButtonIcon
          size="medium"
          icon={
            <FontAwesomeIcon icon={faPaperPlane} style={{ color: '#576574' }} />
          }
          variant="base"
          style={{
            position: 'absolute',
            bottom: check ? '22px' : 0,
            right: 0,
          }}
          onClick={onSubmit}
          ref={sendEmailButton}
        />
      </div>
      {inviteError && <div>{inviteError}</div>}
      <CopyToClipboard text={joinLink} onCopy={notifyCopy}>
        <Input
          label="copy join link"
          icon={<FontAwesomeIcon icon={faCopy} style={{ color: '#576574' }} />}
          iconPosition="right"
          isCentered
          value={joinLink}
          type="url"
          style={{
            margin: '24px 16px 0px',
            minWidth: '200px',
            cursor: 'pointer',
          }}
        />
      </CopyToClipboard>
    </div>
  );
};

SendInvite.propTypes = {
  groupId: PropTypes.string,
  inviteCode: PropTypes.string,
};

const MembersAndInvites = ({
  groupId,
  members,
  invites,
  isAdmin,
  inviteCode,
}) => {
  const unacceptedInvites = invites.filter(inv => !inv.accepted);
  const showMembers = members.length > 0;
  const showInvites = unacceptedInvites.length > 0;
  return (
    <GroupSectionCard>
      {showMembers && <MembersSection members={members} />}
      {showInvites && <InvitesSection unacceptedInvites={unacceptedInvites} />}
      {isAdmin && <SendInvite groupId={groupId} inviteCode={inviteCode} />}
    </GroupSectionCard>
  );
};

MembersAndInvites.propTypes = {
  groupId: PropTypes.string,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      nickname: PropTypes.string,
      email: PropTypes.string,
    }),
  ),
  invites: PropTypes.arrayOf(
    PropTypes.shape({
      accepted: PropTypes.bool,
      email: PropTypes.string,
    }),
  ),
  isAdmin: PropTypes.bool,
  inviteCode: PropTypes.string,
};

const SnippetsStatus = ({
  cycleId,
  due,
  tz,
  paused,
  isAdmin,
  adminNickname,
}) => {
  const { data = {} } = useQuery(USER_HAS_POST_QUERY, {
    variables: { cycleId },
    fetchPolicy: 'cache-and-network',
    skip: !cycleId,
  });

  let content;
  if (paused) {
    content = (
      <div className={cn(styles.snips, styles.on)}>
        <h1>
          <span role="img" aria-label="paused">
            ⏸️
          </span>
        </h1>
        <h1>snippets are paused.</h1>
        {isAdmin ? (
          <h2>you can restart this group in subscription settings.</h2>
        ) : (
          <h2>
            if you still want updates, ask {adminNickname} to restart this
            group.
          </h2>
        )}
      </div>
    );
  } else if (!due) {
    content = (
      <div className={cn(styles.snips, styles.off)}>
        <h1>we&apos;ll get started soon...</h1>
        <h2>
          snippets for this group haven&apos;t started yet. don&apos;t worry,
          we&apos;ll email you when it&apos;s time to submit.
        </h2>
      </div>
    );
  } else {
    const duedate = new Date(due);
    const alert = soon({ due: duedate }) ? styles.alert : '';
    const deadline = timeLeft({ due: duedate, tz });
    const { userHasPostForCycle } = data;

    content = (
      <Link to={`/snippets/${cycleId}`} style={{ textDecoration: 'none' }}>
        <div
          className={cn(styles.snips, styles.on, styles.hoverhighlight, alert)}
        >
          <h1>
            {userHasPostForCycle ? (
              <span role="img" aria-label="write">
                🎉
              </span>
            ) : (
              <span role="img" aria-label="write">
                ✍️
              </span>
            )}
          </h1>
          {userHasPostForCycle ? (
            <h1>snippets submitted.</h1>
          ) : (
            <h1>write your snippets</h1>
          )}
          {userHasPostForCycle ? (
            <h2>have more to say? feel free to edit them.</h2>
          ) : (
            <h2>they&apos;re next due {deadline}.</h2>
          )}
        </div>
      </Link>
    );
  }

  return <GroupSectionCard>{content}</GroupSectionCard>;
};

SnippetsStatus.propTypes = {
  cycleId: PropTypes.string,
  due: PropTypes.string,
  tz: PropTypes.string,
  paused: PropTypes.bool,
  isAdmin: PropTypes.bool,
  adminNickname: PropTypes.string,
};

const Header = ({ groupname, count }) => (
  <GroupSectionCard backgroundColor="#fce588">
    <div className={styles.header}>
      <h1>{groupname}</h1>
      <h2>
        {count} member{count !== 1 && 's'}
      </h2>
    </div>
  </GroupSectionCard>
);

Header.propTypes = {
  groupname: PropTypes.string,
  count: PropTypes.number,
};

const EmbeddedHeader = ({ title }) => (
  <div className={styles.embedded}>
    <h1>{title}</h1>
    <h2>(for your eyes only)</h2>
  </div>
);

EmbeddedHeader.propTypes = {
  title: PropTypes.string,
};

const Group = ({ groupId }) => {
  const { me } = useAuthContext();
  const { loading, error, data = {} } = useQuery(GROUP_QUERY, {
    variables: { groupId },
  });

  if (loading) {
    return <PageLoader label="finding your group..." />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const {
    group: {
      name,
      // frequency,
      admin: {
        id: adminId,
        name: adminName,
        nickname: adminNickname,
        email: adminEmail,
      },
      members,
      invites,
      inviteCode,
      current,
      subscription: { id: subscribeId, paused },
    },
  } = data;

  const memberCount = members.length;
  const userIsAdmin = me.id === adminId;

  return (
    <>
      <Header groupname={name} count={memberCount} />
      <SnippetsStatus
        cycleId={current ? current.id : undefined}
        due={current ? current.due : undefined}
        tz={current ? current.tz : undefined}
        paused={paused}
        isAdmin={userIsAdmin}
        adminNickname={adminNickname}
      />
      <MembersAndInvites
        groupId={groupId}
        members={members}
        invites={invites}
        isAdmin={userIsAdmin}
        inviteCode={inviteCode}
      />
      {userIsAdmin && (
        <>
          <EmbeddedHeader title="admin settings" />
          <Settings
            groupId={groupId}
            groupname={name}
            subscribeId={subscribeId}
            adminName={adminName}
            adminEmail={adminEmail}
            paused={paused}
          />
        </>
      )}
    </>
  );
};

Group.propTypes = {
  groupId: PropTypes.string,
};

export default Group;

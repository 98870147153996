import React from 'react';

function RenderEditor(options) {
  const { renderFn, args } = options;

  return {
    renderEditor(props, editor, next) {
      const children = next();
      return (
        <>
          {children}
          {renderFn(editor, args)}
        </>
      );
    },
  };
}

export default RenderEditor;

// Only send data to Segment if it's the production app or
// the development app
// Source WriteKey is different in template.html based on
// location.hostname

const envCheck =
  process.env.BACKEND_STAGE === 'prod' ||
  process.env.NODE_ENV === 'development';

const actions = {
  page: (name, props) => {
    if (envCheck) {
      window.analytics.page(name, props);
    }
  },
  identify: (id, traits) => {
    if (envCheck) {
      window.analytics.identify(id, traits);
    }
  },
  group: (id, traits) => {
    if (envCheck) {
      window.analytics.group(id, traits);
    }
  },
};

export const Segment = actions;

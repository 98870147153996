import React from 'react';
import PropTypes from 'prop-types';
import dateformat from 'dateformat';
import styled from '@emotion/styled';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 24px 0px;
`;

const ContextHeader = styled('h1')`
  font-family: 'Roboto', sans-serif;
  font-size: 56px;
  font-weight: bold;
  font-stretch: condensed;
  text-transform: lowercase;
  text-align: center;
  color: #a4002b;
  opacity: 0.35;
`;

const Shift = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: -14px;
  align-items: center;
`;

const Controls = styled('div')`
  display: flex;
  flex-direction: row;
  font-family: 'Lato', sans-serif;
  font-size: 32px;
  font-weigt: bold;
  text-transform: lowercase;
  color: white;
  margin-bottom: 12px;

  h3 {
    padding: 0 24px;
  }
`;

const DayHeader = styled('h4')`
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  text-transform: lowercase;
  color: white;
`;

const frequencyToString = {
  1: 'one week',
  2: 'two weeks',
  3: 'three weeks',
  4: 'one month',
};

const DateNav = ({ date, frequency }) => {
  const formattedDate = dateformat(date, 'mmmm dS');
  const frequencyString = frequencyToString[frequency];

  const now = new Date();
  const weekLater = new Date();
  weekLater.setDate(now.getDate() + 7);
  // const thisWeek = date >= now && date <= weekLater;

  return (
    <Container>
      <ContextHeader>{frequencyString} ending</ContextHeader>
      <Shift>
        <Controls>
          <h3>{formattedDate}</h3>
        </Controls>
        <DayHeader>sunday</DayHeader>
      </Shift>
    </Container>
  );
};

DateNav.propTypes = {
  date: PropTypes.instanceOf(Date),
  frequency: PropTypes.number,
};

export default DateNav;

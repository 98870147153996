import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from '../styles/FormInput.scss';
import { a11yHandleEnterKeyPress } from '../utils/a11y';

function AutoNickNameLabel(props) {
  const { showLabel, value, onClick, overrideStyle } = props;
  const showLabelStyle = showLabel ? styles.show : styles.none;
  const addedStyle = overrideStyle || '';

  return (
    <div className={cn(styles.inputLabel, showLabelStyle, addedStyle)}>
      <span className={styles.addNickNameLabel}>welcome, {value}.</span>{' '}
      <span
        onClick={onClick}
        className={styles.changeNickNameButton}
        role="button"
        tabIndex={0}
        onKeyPress={a11yHandleEnterKeyPress(onClick)}
      >
        change nickname
      </span>
    </div>
  );
}

AutoNickNameLabel.propTypes = {
  showLabel: PropTypes.bool,
  value: PropTypes.string,
  onClick: PropTypes.func,
  overrideStyle: PropTypes.string,
};

export default AutoNickNameLabel;

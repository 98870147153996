import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/Headers.scss';

const HeaderTertiary = props => {
  const { label } = props;
  return (
    <div className={styles.htertiary}>
      <h5>{label}</h5>
    </div>
  );
};

HeaderTertiary.propTypes = {
  label: PropTypes.string,
};

export default HeaderTertiary;

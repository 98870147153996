import React from 'react';
import Typist from 'react-typist';
import styles from '../styles/Headers.scss';

class HeaderPrimary extends React.Component {
  shouldComponentUpdate() {
    return false; // force it not to update on parent component prop change so that the typing animation doesn't restart and create jank
  }

  render() {
    return (
      <div className={styles.hprimary}>
        <h1>
          <span>don&apos;t lose touch with </span>
          <Typist>
            <span>the people who matter.</span>
            <Typist.Backspace count={22} delay={3500} />
            <span>college friends</span>
            <Typist.Backspace count={15} delay={1750} />
            <span>childhood friends</span>
            <Typist.Backspace count={17} delay={1750} />
            <span>friends in other cities</span>
            <Typist.Backspace count={23} delay={1750} />
            <span>your homies.</span>
            <Typist.Backspace count={12} delay={9000} />
            <span>the people who matter.</span>
          </Typist>
        </h1>
      </div>
    );
  }
}

export default HeaderPrimary;

import Hotkey from '../helpers/Hotkey';
import RenderMark from '../helpers/RenderMark';

function Underline(options) {
  const { type } = options;
  return [
    Hotkey({ hotkey: 'mod+u', fn: editor => editor.toggleMark(type) }),
    RenderMark({
      type,
      renderFn: (attributes, children) => {
        return <u {...attributes}>{children}</u>;
      },
    }),
  ];
}

export default Underline;

import isHotkey from 'is-hotkey';

function Hotkey(options) {
  const { hotkey, fn } = options;

  return {
    onKeyDown(event, editor, next) {
      // let isHotKeyEvent;

      // if (typeof hotkey === 'string') {
      //   isHotKeyEvent = isHotkey(hotkey, event);
      // } else {
      //   const { macKey, winKey } = hotkey;
      //   isHotKeyEvent =
      //     isHotKeyEvent(macKey, event) || isHotKeyEvent(winKey, event);
      // }

      if (!isHotkey(hotkey, event)) return next();

      event.preventDefault();

      editor.command(fn);

      return true;
    },
  };
}

export default Hotkey;

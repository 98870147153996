import gql from 'graphql-tag';

// a component that houses shared mutations and queries to avoid circular dependencies
// between parent and child components

const CARD_INFO_QUERY = gql`
  query PlanQuery($subscribeId: ID!) {
    card(subscribeId: $subscribeId) {
      brand
      last4
      expMonth
      expYear
    }
  }
`;

const PLAN_INFO_QUERY = gql`
  query PlanQuery($subscribeId: ID!) {
    plan(subscribeId: $subscribeId) {
      status
      price
      frequency
      nextBillDate
      trialEndDate
      cancelAtPeriodEnd
      card {
        brand
        last4
        expMonth
        expYear
      }
    }
  }
`;

const USER_HAS_POST_QUERY = gql`
  query userHasPostQuery($cycleId: ID!) {
    userHasPostForCycle(cycleId: $cycleId)
  }
`;

export { CARD_INFO_QUERY, PLAN_INFO_QUERY, USER_HAS_POST_QUERY };

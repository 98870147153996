import React from 'react';
import PropTypes from 'prop-types';
import {
  VisualPicker,
  VisualPickerOption,
  VisualPickerOptionFooter,
} from 'react-rainbow-components';
import Section from './Section';
import styles from '../styles/PlanSection.scss';

const pickerStyle = {
  fontFamily: 'Lato, sans-serif',
};

const titleStyle = {
  fontFamily: 'Roboto, sans-serif',
  fontSize: 36,
  color: '#061c3f',
  fontWeight: 200,
  marginBottom: '8px',
};

const descriptionStyle = {
  fontFamily: 'Lato, sans-serif',
  fontSize: 14,
  color: '#a4a7b5',
  fontWeight: 300,
};

const YearlyFooter = () => (
  <VisualPickerOptionFooter
    label="yearly"
    description="save 50%, covers the entire group"
    className={styles.visualPickerFooter}
  />
);

const MonthlyFooter = () => (
  <VisualPickerOptionFooter
    label="monthly"
    description="covers the entire group"
    className={styles.visualPickerFooter}
  />
);

const PlanSection = ({ selected, onPlanChange }) => {
  return (
    <Section
      name="choose group plan"
      description={`your plan covers the entire group. \n\n add as many members as you like and split the cost.`}
    >
      <VisualPicker
        id="visual-picker-component-5"
        label="3-month free trial, then..."
        value={selected}
        onChange={onPlanChange}
        style={pickerStyle}
      >
        <VisualPickerOption
          name="yearly"
          footer={<YearlyFooter />}
          className={styles.visualPickerOption}
        >
          <h2 style={titleStyle}>$30</h2>
          <h3 style={descriptionStyle}>/group/yr</h3>
        </VisualPickerOption>
        <VisualPickerOption
          name="monthly"
          footer={<MonthlyFooter />}
          className={styles.visualPickerOption}
        >
          <h2 style={titleStyle}>$5</h2>
          <h3 style={descriptionStyle}>/group/mo</h3>
        </VisualPickerOption>
      </VisualPicker>
    </Section>
  );
};

PlanSection.propTypes = {
  selected: PropTypes.string,
  onPlanChange: PropTypes.func,
};

export default PlanSection;

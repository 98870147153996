function RenderMark(options) {
  const { type, renderFn } = options;

  return {
    renderMark(props, editor, next) {
      const { attributes, mark, children } = props;
      if (mark.type === type) {
        return renderFn(attributes, children);
      }
      return next();
    },
  };
}

export default RenderMark;

import * as Sentry from '@sentry/browser';
import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './global.scss';
import AppProvider from './components/context/AppProvider';
import App from './components/App';

Sentry.init({
  dsn: 'https://104eada47be34233aaa7e476e186d976@sentry.io/140864',
  environment: process.env.SENTRY_ENVIRONMENT || 'react-dev',
  release: process.env.RELEASE || 'no-release',
});

ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById('app'),
);

import Hotkey from '../helpers/Hotkey';
import RenderMark from '../helpers/RenderMark';

function Bold(options) {
  const { type } = options;
  return [
    Hotkey({ hotkey: 'mod+b', fn: editor => editor.toggleMark(type) }),
    RenderMark({
      type,
      renderFn: (attributes, children) => {
        return <strong {...attributes}>{children}</strong>;
      },
    }),
  ];
}

export default Bold;

import React from 'react';
import styles from '../styles/How.scss';

function How() {
  return (
    <section className={styles.how}>
      <div className={styles.headers}>
        <h1>how it works</h1>
        <h2>
          you bring a group of homies, snippets will keep y&apos;all close
        </h2>
      </div>
      <ul className={styles.reasons}>
        <li>
          <h3>ongoing catch up</h3>
          <p>
            snippets get collected every 3 weeks. everyone writes 5 bullets on
            what&apos;s been up with them since last time by sunday at midnight.
            a newsletter with everyone&apos;s updates is delivered to your inbox
            on monday morning.
          </p>
        </li>
        <li>
          <h3>authentic, private updates</h3>
          <p>
            snippets can be about anything, from strugs at work to your new boo
            to the tasty avocado you had for lunch. no need to select the
            perfect filter. only your homies see what you write so you can keep
            it real.
          </p>
        </li>
        <li>
          <h3>you&apos;ll feel like you know your friends again</h3>
          <p>
            don&apos;t let distance, time or filters decay your most important
            relationships. stop wasting precious in-person hangs having the same
            boring catch up conversations.
          </p>
        </li>
        <div className={styles.startLink}>
          <a href="mailto:start@dosnippets.com">
            email start@dosnippets.com to set your group up --&gt;
          </a>
        </div>
      </ul>
    </section>
  );
}

export default How;

import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/react-common';
import client from '../ApolloClient';
import AuthProvider from './AuthProvider';
import GroupProvider from './GroupProvider';

const AppProvider = ({ children }) => (
  <ApolloProvider client={client}>
    <AuthProvider>
      <GroupProvider>{children}</GroupProvider>
    </AuthProvider>
  </ApolloProvider>
);

AppProvider.propTypes = {
  children: PropTypes.node,
};

export default AppProvider;

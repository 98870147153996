import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Card from 'react-rainbow-components/components/Card';
import { Segment } from '../utils/segment';
// import { useAuthContext } from './context/AuthProvider';
import { useGroupContext } from './context/GroupProvider';
import HandleError from './HandleError';
import PageLoader from './PageLoader';
import Nav from './Nav';
import ErrorBoundary from './ErrorBoundary';
import SnippetsContainer from './SnippetsContainer';
import styles from '../styles/SnippetsPage.scss';

const SPECIFIC_POST_QUERY = gql`
  query PostQuery($cycleId: ID!) {
    userPostForCycle(cycleId: $cycleId) {
      id
      updatedAt
      cycle {
        due
        group {
          id
          name
          frequency
        }
      }
    }
  }
`;

const NoCyclesError = ({ title, groupname }) => {
  return (
    <Card className={styles.error} style={{ backgroundColor: '#fce588' }}>
      <h3>{title} for</h3>
      <h1>{groupname}</h1>
      <h2>
        ...but they will soon! we&apos;ll email you when it&apos;s time to
        submit your updates for this group
      </h2>
      <h2>
        sit tight until then{' '}
        <span role="img" aria-label="hourglass">
          ⏳
        </span>
      </h2>
    </Card>
  );
};

NoCyclesError.propTypes = {
  title: PropTypes.string,
  groupname: PropTypes.string,
};

const SelectedGroupSnippets = () => {
  const { group } = useGroupContext();

  let cycleId;
  if (group) {
    const { current } = group;
    cycleId = current ? current.id : undefined;
  }

  const { loading, error, data = {} } = useQuery(SPECIFIC_POST_QUERY, {
    variables: { cycleId },
    skip: !cycleId,
  });

  if (!cycleId) {
    return (
      <NoCyclesError
        title="snippets haven't started yet"
        groupname={group ? group.name : ''}
      />
    );
  }

  if (loading) return <PageLoader label="how's your week going?" />;

  if (error) {
    return (
      <HandleError error={error}>
        {friendlyMsg => {
          return <div>{friendlyMsg}</div>;
        }}
      </HandleError>
    );
  }

  const {
    userPostForCycle: {
      id: postId,
      cycle: {
        due,
        group: { id: groupId, name, frequency },
      },
    },
  } = data;

  Segment.group(groupId, {
    name,
  });

  return (
    <SnippetsContainer
      postId={postId}
      groupId={group.id}
      due={due}
      groupname={name}
      frequency={frequency}
    />
  );
};

// default -- use the selected groups current cycle
// if cycleId provided, switch the selected group, but go to that specific post
// MAJOR ASSUMPTION: cycleId is a current cycle for a group.
// When you handle past you need to update this.

const SnippetsPage = ({ match }) => {
  const { cycleId } = match.params;

  const { group, selectGroupByCycleId } = useGroupContext();
  const [useParam, setUseParam] = useState(!!cycleId);

  useEffect(() => {
    Segment.page('Compose');
  }, []);

  useEffect(() => {
    if (useParam) {
      if (group) {
        const { current } = group;
        const selectedGroupCycleId = current ? current.id : undefined;
        if (selectedGroupCycleId !== cycleId) {
          selectGroupByCycleId(cycleId);
          setUseParam(false);
        }
      }
    }
  }, [cycleId, group, selectGroupByCycleId, setUseParam, useParam]);

  return (
    <ErrorBoundary>
      <div className={styles.page}>
        <Nav showAuthLinks fill />
        <SelectedGroupSnippets />
      </div>
    </ErrorBoundary>
  );
};

SnippetsPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      cycleId: PropTypes.string,
    }).isRequired,
  }),
};

export default SnippetsPage;

import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { ClipLoader } from 'react-spinners';
import styles from '../styles/ButtonRound.scss';
import { a11yHandleEnterKeyPress } from '../utils/a11y';

const override = css`
  margin-top: 200px;
`;

const ButtonRound = props => {
  const { text, onClick, loading } = props;
  return (
    <>
      {loading ? (
        <ClipLoader
          css={override}
          sizeUnit="px"
          size={24}
          color="#F7C948"
          loading={loading}
        />
      ) : (
        <div
          className={styles.button}
          type="button"
          onClick={onClick}
          role="button"
          tabIndex={0}
          onKeyPress={a11yHandleEnterKeyPress(onClick)}
        >
          <div>{text}</div>
        </div>
      )}
    </>
  );
};

ButtonRound.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

ButtonRound.defaultProps = {
  loading: false,
};

export default ButtonRound;

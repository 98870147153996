import React, {
  useContext,
  createContext,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  CURRENT_USER_QUERY,
  LINK_LOGIN_MUTATION,
  VALIDATE_SHORT_URL_MUTATION,
  LOG_OUT_MUTATION,
  logUser,
} from '../User';

const noop = () => {};

const AuthContext = createContext({ me: null, login: noop, logout: noop });

const AuthProvider = props => {
  const {
    data: meData = {},
    loading: meLoading,
    error: meError,
    refetch,
  } = useQuery(CURRENT_USER_QUERY);

  const [linkLogin, { loading: loginLoading, error: loginError }] = useMutation(
    LINK_LOGIN_MUTATION,
  );

  const [
    validateShortUrl,
    { loading: validateLoading, error: validateError },
  ] = useMutation(VALIDATE_SHORT_URL_MUTATION);

  const [
    logoutMutation,
    { loading: logoutLoading, error: logoutError },
  ] = useMutation(LOG_OUT_MUTATION, {
    refetchQueries: [{ query: CURRENT_USER_QUERY }],
  });

  /* Memoize the apollo versions so as not to trigger new
    api functions, which would get stuck in inifinite render
    loop
  */
  const refetchRef = useRef();

  useEffect(() => {
    refetchRef.current = refetch;
  }, [refetch]);

  const linkLoginRef = useRef();

  useEffect(() => {
    linkLoginRef.current = linkLogin;
  }, [linkLogin]);

  const login = useCallback(
    async linkToken => {
      const res = await linkLoginRef.current({
        variables: {
          linkToken,
        },
      });
      await refetchRef.current();
      return res.data.loginWithLink;
    },
    [linkLoginRef, refetchRef],
  );

  const validateShortUrlRef = useRef();

  useEffect(() => {
    validateShortUrlRef.current = validateShortUrl;
  }, [validateShortUrl]);

  const validate = useCallback(
    async shortId => {
      const res = await validateShortUrlRef.current({
        variables: {
          shortId,
        },
      });
      await refetchRef.current();
      return res.data.validateShortUrl;
    },
    [validateShortUrlRef, refetchRef],
  );

  const logoutMutationRef = useRef();

  useEffect(() => {
    logoutMutationRef.current = logoutMutation;
  }, [logoutMutation]);

  const logout = useCallback(async () => {
    await logoutMutationRef.current();
    await refetchRef.current();
  }, [logoutMutationRef, refetchRef]);

  const loading = {
    me: meLoading,
    login: loginLoading,
    logout: logoutLoading,
    validate: validateLoading,
  };
  const error = {
    me: meError,
    login: loginError,
    logout: logoutError,
    validate: validateError,
  };

  const authValue = useMemo(
    () => ({
      ...meData,
      login,
      validate,
      logout,
      loading,
      error,
    }),
    // need this b/c on each render of the component, react makes new
    // functions for login/logout, so value gets updated. When value
    // gets updated, triggers re-render of children of provider

    [meData, login, validate, logout, loading, error],
  );

  if (meData.me) {
    logUser(meData.me);
  }

  if (meLoading) {
    return <></>;
    // return (
    //   <div
    //     style={{
    //       display: 'flex',
    //       flexFlow: 'column',
    //       height: '100vh',
    //     }}
    //   >
    //     <PageLoader label="" />
    //   </div>
    // );
  }

  return <AuthContext.Provider value={authValue} {...props} />;
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from './Toolbar';

// list utils

const hasParentOfType = (value, type) =>
  value.blocks.some(
    block =>
      !!value.document.getClosest(block.key, parent => parent.type === type),
  );

const isUnorderedList = value => hasParentOfType(value, 'ul_list');
const isOrderedList = value => hasParentOfType(value, 'ol_list');

const ListToolbarButtons = ({ editorRef }) => {
  if (!editorRef.current) return <></>;
  const editor = editorRef.current;
  const { value } = editor;

  const onClickBlockButton = (event, type) => {
    event.preventDefault();
    editor.toggleList({ type });
  };

  const renderBlockButton = (type, icon) => {
    const isActive =
      type === 'ul_list' ? isUnorderedList(value) : isOrderedList(value);

    return (
      <Button
        active={isActive}
        onMouseDown={event => onClickBlockButton(event, type)}
      >
        <Icon>{icon}</Icon>
      </Button>
    );
  };

  return (
    <>
      {renderBlockButton('ul_list', 'format_list_bulleted')}
      {renderBlockButton('ol_list', 'format_list_numbered')}
    </>
  );
};

ListToolbarButtons.propTypes = {
  editorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default ListToolbarButtons;

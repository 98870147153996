import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import Nav from './Nav';
import HeaderSecondary from './HeaderSecondary';
import HeaderTertiary from './HeaderTertiary';
import ButtonRound from './ButtonRound';
import SvgRobot from './svgs/Robot';
import grid from '../styles/PageGrid.scss';
import styles from '../styles/ErrorBoundary.scss';

const RobotImage = () => <SvgRobot />;

const SomethingWentWrong = props => {
  const { onReport } = props;
  return (
    <div className={grid.container}>
      <Nav showAuthLinks={false} />
      <div className={styles.content}>
        <div className={grid.value}>
          <div className={grid.form}>
            <div className={styles.textspace}>
              <HeaderSecondary label="we're sorry, something went wrong..." />
              <HeaderTertiary
                label={`it'd be a huge help if you could tell us how you got here so we can fix our robots faster`}
              />
              <br />
              <br />
              <br />
              <div className={styles.actions}>
                <ButtonRound text="report problem" onClick={onReport} />
              </div>
            </div>
          </div>
          <div className={grid.image}>
            <RobotImage />
          </div>
        </div>
      </div>
    </div>
  );
};

SomethingWentWrong.propTypes = {
  onReport: PropTypes.func,
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  report = () => {
    const { eventId } = this.state;
    Sentry.showReportDialog({ eventId });
  };

  render() {
    const { error } = this.state;

    if (error) return <SomethingWentWrong onReport={this.report} />;
    // no error so render children untouched
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

export default ErrorBoundary;

import React from 'react';
import PropTypes from 'prop-types';
import ButtonRound from './ButtonRound';
import styles from '../styles/LandingPage.scss';

class EmailCapture extends React.Component {
  constructor(props) {
    super(props);

    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      const { onSubmit } = this.props;
      onSubmit();
    }
  }

  render() {
    const {
      email,
      handleEmailChange,
      onSubmit,
      placeholder,
      buttonText,
      loading,
    } = this.props;

    return (
      <div className={styles.signUp}>
        <table>
          <tbody>
            <tr>
              <td className={styles.placeCell}>
                <div className={styles.placeContainer}>
                  <input
                    className={styles.place}
                    placeholder={placeholder}
                    value={email}
                    tabIndex={0}
                    spellCheck={false}
                    /* eslint-disable */
                    autoFocus
                    /* eslint-enable */
                    onChange={handleEmailChange}
                    onKeyPress={this.handleKeyPress}
                    name="email"
                  />
                </div>
              </td>
              <td className={styles.buttonCell}>
                <ButtonRound
                  text={buttonText}
                  onClick={onSubmit}
                  loading={loading}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

EmailCapture.propTypes = {
  placeholder: PropTypes.string,
  email: PropTypes.string,
  handleEmailChange: PropTypes.func,
  onSubmit: PropTypes.func,
  buttonText: PropTypes.string,
  loading: PropTypes.bool,
};

EmailCapture.defaultProps = {
  loading: false,
};

export default EmailCapture;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { useAuthContext } from './context/AuthProvider';
import ErrorBoundary from './ErrorBoundary';
import Nav from './Nav';
import PageLoader from './PageLoader';

import styles from '../styles/SnippetsPage.scss';

const ShortUrl = ({ match }) => {
  const { shortid } = match.params;
  const [redirect, setRedirect] = useState(undefined);
  const { validate } = useAuthContext();

  useEffect(() => {
    const followId = async () => {
      try {
        const { route } = await validate(shortid);
        setRedirect(route);
      } catch (err) {
        console.error(`Error trying to validateShortUrl: `, err);
        Sentry.captureException(err);
      }
    };

    if (shortid) {
      followId();
    }
  }, [shortid, validate]);

  if (redirect) return <Redirect to={redirect} />;

  return (
    <ErrorBoundary>
      <div className={styles.page}>
        <Nav blank fill thick />
        <PageLoader label="following the link..." />
      </div>
    </ErrorBoundary>
  );
};

ShortUrl.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      shortid: PropTypes.string,
    }),
  }),
};

// export default graphql(VALIDATE_SHORT_URL_MUTATION, {
//   name: 'validateShortUrl',
//   options: props => ({
//     variables: {
//       shortId: props.match.params.shortid,
//     },
//     refetchQueries: [{ query: CURRENT_USER_QUERY }],
//   }),
// })(ShortUrl);

export default ShortUrl;

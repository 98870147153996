import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import { useQuery } from '@apollo/react-hooks';
import { useDebouncedCallback } from 'use-debounce';
import { Editor } from 'slate-react';
import Plain from 'slate-plain-serializer';
import { ApolloClient } from 'apollo-client';
import gql from 'graphql-tag';
import { graphql, withApollo } from '@apollo/react-hoc';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/browser';
import Lists from '@convertkit/slate-lists';
import html from './plugins/helpers/HtmlSerializer';
import HoverMenuPlugin from './plugins/feature/HoverMenu';
import Placeholder, {
  PlaceholderToolbarButton,
} from './plugins/feature/Placeholder';
import SavingIndicator from './plugins/feature/SavingIndicator';
import Bold from './plugins/feature/Bold';
import Italic from './plugins/feature/Italic';
import Underline from './plugins/feature/Underline';
import Strikethrough from './plugins/feature/Strikethrough';
import Links from './plugins/feature/Links';
import ListToolbarButtons from './plugins/feature/ListButtons';
import EmojiMenu, { EmojiToolbarButton } from './plugins/feature/Emoji';
import EmojiAutosuggestPlugin from './plugins/feature/EmojiAutosuggest';
import Toolbar, { Instruction } from './plugins/feature/Toolbar';
import styles from '../../styles/SnippetEditor.scss';

const POST_QUERY = gql`
  query postQuery($postId: ID!) {
    post(postId: $postId) {
      id
      updatedAt
      content
    }
  }
`;

const ONE_QUESTION_QUERY = gql`
  query OneQuestionQuery($groupId: ID!) {
    oneQuestion(groupId: $groupId) {
      question
      description
    }
  }
`;

const Palette = styled('div')`
  position: relative;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 30px;
  border-radius: 6px;
  border-left: solid 2px #f5f7fa;
  border-right: solid 2px #f5f7fa;
  background: white;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  line-height: 1.4;
  transform: translateY(-152px);

  &:focus,
  &:active {
    outline: 0;
  }
`;

const menuRef = React.createRef();
const panelRef = React.createRef();

const plugins = [
  Lists({
    blocks: {
      ordered_list: 'ol_list',
      unordered_list: 'ul_list',
      list_item: 'list_item',
    },
    classNames: {
      ordered_list: styles.ol_list,
      unordered_list: styles.ul_list,
      list_item: styles.list_item,
    },
  }),
  Italic({ type: 'italic' }),
  Bold({ type: 'bold' }),
  Underline({ type: 'underlined' }),
  Strikethrough({ type: 'strikethrough' }),
  Links({ type: 'link' }),
  HoverMenuPlugin({ menuRef }),
  EmojiAutosuggestPlugin({ panelRef }),
];

const SnippetEditor = ({
  disabled,
  postId,
  groupId,
  client,
  savePost,
  groupname,
  nickname,
}) => {
  const editorRef = useRef(undefined);
  const [editorValue, setEditorValue] = useState(Plain.deserialize(''));
  const [showPlaceholder, setShowPlaceholder] = useState(false);
  const [userChosePlaceholder, setUserChosePlaceholder] = useState(undefined);
  const [savedAt, setSavedAt] = useState(new Date());
  const [saving, setSaving] = useState(false);
  const [showEmojiMenu, setShowEmojiMenu] = useState(false);
  const [bottomSaveIndicatorRef, bottomSaveIndicatorInView] = useInView({
    threshold: 0.4,
  });
  const { data: { oneQuestion } = {} } = useQuery(ONE_QUESTION_QUERY, {
    variables: {
      groupId,
    },
  });

  const editorEmptyish = useCallback(
    value => value.document.text.trim().length <= 4,
    [],
  );

  // TODO today when page is reloaded, it flashes
  // TODO the cursor in the editor
  // TODO but then when placeholder is loaded, it loses focus
  useEffect(() => {
    editorRef.current.focus();
  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const result = await client.query({
          query: POST_QUERY,
          variables: { postId },
        });
        const {
          data: { post },
        } = result;
        if (post) {
          // const existingValue = JSON.parse(post.content);
          // const initialValue = Value.fromJSON(existingValue);
          const initialValue = html.deserialize(post.content);
          setEditorValue(initialValue);
          setShowPlaceholder(editorEmptyish(initialValue));
          setSavedAt(new Date(post.updatedAt));
        } else {
          setShowPlaceholder(true);
        }
      } catch (err) {
        Sentry.captureException(err);
        console.error('Error loading initial post', err);
      }
    };

    fetchPost();
  }, [client, editorEmptyish, postId]);

  const handleSave = async valueToSave => {
    setSaving(true);

    const plainSerialized = Plain.serialize(valueToSave).trim();
    const serializedValue =
      plainSerialized !== '' ? html.serialize(valueToSave) : plainSerialized;
    const optimisticSaveDate = new Date();

    try {
      const result = await savePost({
        variables: {
          postId,
          content: serializedValue,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          savePost: {
            id: postId,
            __typename: 'Post',
            content: serializedValue,
            updatedAt: optimisticSaveDate.toISOString(),
          },
        },
        update: (
          store,
          {
            data: {
              savePost: { content },
            },
          },
        ) => {
          const data = store.readQuery({
            query: POST_QUERY,
            variables: { postId },
          });
          data.post.content = content;
          store.writeQuery({
            query: POST_QUERY,
            variables: { postId },
            data,
          });
        },
      });
      const { data } = result;
      // onSave(false, data.savePost.updatedAt);
      setSaving(false);
      setSavedAt(new Date(data.savePost.updatedAt));
    } catch (err) {
      console.error(`Error handling save: `, err);
      Sentry.captureException(err); // TODO handle errors right and only occassionally
    }
  };

  const [debouncedSave] = useDebouncedCallback(handleSave, 500, {
    maxWait: 4000,
  });

  const onChange = ({ value }) => {
    setEditorValue(value);

    const changeText = editorValue.document !== value.document;

    if (changeText) {
      if (userChosePlaceholder !== undefined) {
        setShowPlaceholder(userChosePlaceholder);
      } else {
        setShowPlaceholder(editorEmptyish(value));
      }
      // console.log(`NEW VALUE`, JSON.stringify(value.document.nodes, null, 2));
      debouncedSave(value);
    }
  };

  const togglePlaceholderKeptOn = event => {
    event.preventDefault();
    setUserChosePlaceholder(!showPlaceholder);
    setShowPlaceholder(!showPlaceholder);
  };

  const toggleEmojiMenu = event => {
    event.preventDefault();
    setShowEmojiMenu(!showEmojiMenu);
  };

  const showInlineSavingIndicator =
    !bottomSaveIndicatorInView && !editorEmptyish(editorValue);

  const editorClassName = disabled
    ? cn(styles.editor, styles.preview)
    : styles.editor;

  return (
    <Palette
      onMouseDown={() => {
        editorRef.current.focus();
      }}
      key="snippet-palette"
    >
      <Instruction key="snippet-instructions">
        <p>
          hi {nickname.toLowerCase()}, <br />
          write a few reflections on what&apos;s been happening lately.
          <br />
          your snippets will be sent out early monday morning to your group,{' '}
          {groupname.toLowerCase()}.
        </p>
      </Instruction>
      <Toolbar key="snippet-toolbar">
        <ListToolbarButtons editorRef={editorRef} key="snippet-list-button" />
        <EmojiToolbarButton
          isActive={showEmojiMenu}
          handleClick={toggleEmojiMenu}
          key="snippet-emoji-button"
        />
        <PlaceholderToolbarButton
          isActive={showPlaceholder}
          handleClick={togglePlaceholderKeptOn}
          key="snippet-placeholder-button"
        />
        <EmojiMenu
          show={showEmojiMenu}
          handleClose={() => setShowEmojiMenu(false)}
          editorRef={editorRef}
          key="snippet-emoji-menu"
        />
      </Toolbar>
      <Editor
        autoFocus
        readOnly={disabled}
        spellCheck={false}
        className={editorClassName}
        ref={editorRef}
        value={editorValue}
        plugins={plugins}
        onChange={onChange}
        placeholder="what's been going on?"
        key="snippet-slate-editor"
        tabIndex={0}
      />
      {showInlineSavingIndicator && (
        <SavingIndicator
          date={savedAt}
          saving={saving}
          key="snippet-saving-indicator-top"
        />
      )}
      <Placeholder
        editorRef={editorRef}
        show={showPlaceholder}
        startChip={0}
        oneQuestion={oneQuestion}
        key="snippet-placeholder"
      />
      <SavingIndicator
        date={savedAt}
        saving={saving}
        bottom
        ref={bottomSaveIndicatorRef}
        key="snippet-saving-indicator-bottom"
      />
    </Palette>
  );
};

SnippetEditor.propTypes = {
  disabled: PropTypes.bool,
  savePost: PropTypes.func,
  client: PropTypes.instanceOf(ApolloClient),
  postId: PropTypes.string,
  groupId: PropTypes.string,
  groupname: PropTypes.string,
  nickname: PropTypes.string,
};

SnippetEditor.defaultProps = {
  disabled: false,
};

const SAVE_MUTATION = gql`
  mutation SavePost($postId: ID!, $content: String!) {
    savePost(postId: $postId, content: $content) {
      id
      updatedAt
      content
    }
  }
`;

export default compose(
  withApollo,
  graphql(SAVE_MUTATION, { name: 'savePost' }),
)(SnippetEditor);

import React from 'react';

const SvgMail = props => (
  <svg width={656} viewBox="0 0 656 676" {...props}>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="mail_svg__b">
        <stop stopColor="#AF3B6E" stopOpacity={0} offset="0%" />
        <stop stopOpacity={0.2} offset="100%" />
      </linearGradient>
      <path
        d="M0 275C216.74 91.667 326.073 0 328 0c1.927 0 111.26 91.667 328 275v371c0 16.569-13.431 30-30 30H30c-16.569 0-30-13.431-30-30V275z"
        id="mail_svg__a"
      />
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="mail_svg__d">
        <stop stopColor="#FFF" stopOpacity={0} offset="0%" />
        <stop stopOpacity={0.05} offset="100%" />
      </linearGradient>
      <rect id="mail_svg__c" x={50} y={275} width={556} height={401} rx={30} />
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="mail_svg__f">
        <stop stopColor="#E478A3" stopOpacity={0} offset="0%" />
        <stop stopColor="#AF3B6E" stopOpacity={0.3} offset="100%" />
      </linearGradient>
      <path
        d="M328 510l328-235v371c0 16.569-13.431 30-30 30H30c-16.569 0-30-13.431-30-30V275l328 235z"
        id="mail_svg__e"
      />
      <linearGradient x1="0%" y1="0%" x2="100%" y2="0%" id="mail_svg__g">
        <stop stopColor="#AF3B6E" stopOpacity={0} offset="0%" />
        <stop stopOpacity={0.1} offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <use fill="#AF3B6E" xlinkHref="#mail_svg__a" />
        <use fill="url(#mail_svg__b)" xlinkHref="#mail_svg__a" />
      </g>
      <g>
        <use fill="#FFF" xlinkHref="#mail_svg__c" />
        <use fill="url(#mail_svg__d)" xlinkHref="#mail_svg__c" />
      </g>
      <g>
        <use fill="#E478A3" xlinkHref="#mail_svg__e" />
        <use fill="url(#mail_svg__f)" xlinkHref="#mail_svg__e" />
      </g>
      <path
        d="M0 275v-1c8.473-7.1 16.781-14.12 25-21l303 218 303-218c8.219 6.88 16.527 13.9 25 21v1L328 511 0 275z"
        fill="url(#mail_svg__g)"
      />
    </g>
  </svg>
);

export default SvgMail;

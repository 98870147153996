import { hot } from 'react-hot-loader/root';
import React from 'react';
import { toast, Zoom } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import GroupPage from './GroupPage';
import LandingPage from './LandingPage';
import NotFoundPage from './NotFoundPage';
import ConversionPage from './ConversionPage';
import InvitedUserPage from './InvitedUserPage';
import SnippetsPage from './SnippetsPage';
import MagicLinkPage from './MagicLinkPage';
import ShortUrl from './ShortUrl';

import styles from '../styles/App.scss';

toast.configure({
  autoClose: 4000,
  draggable: isMobile,
  draggablePercent: 70,
  position: isMobile ? toast.POSITION.TOP_CENTER : toast.POSITION.BOTTOM_CENTER,
  transition: Zoom,
  className: styles.toastContainer,
  toastClassName: styles.toastStyles,
  bodyClassName: styles.toastBody,
  progressClassName: styles.toastProgress,
});

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route path="/convert/:id/:groupname" component={ConversionPage} />
      <Route path="/convert/:id" component={ConversionPage} />
      <Route path="/join/:inviteCode" component={InvitedUserPage} />
      <Route path="/setup/:id" component={InvitedUserPage} />
      <Route exact path="/magiclink" component={MagicLinkPage} />
      <Route path="/magiclink/:token/:after" component={MagicLinkPage} />
      <Route path="/magiclink/:token" component={MagicLinkPage} />
      <Route path="/x/:shortid" component={ShortUrl} />
      <PrivateRoute exact path="/snippets" component={SnippetsPage} />
      <PrivateRoute path="/snippets/:cycleId" component={SnippetsPage} />
      <PrivateRoute exact path="/group" component={GroupPage} />
      <PrivateRoute path="/group/:groupId" component={GroupPage} />
      <Route component={NotFoundPage} />
    </Switch>
  </BrowserRouter>
);

export default hot(App);

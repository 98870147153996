const _ = require('lodash');

function possessive(name) {
  return name.slice(-1) === 's' ? name + "'" : name + "'s";
}

function extractOthersList(invites, email) {
  const filteredList = _.filter(invites, el => el.email !== email);
  const justEmails = _.map(filteredList, el => el.email);
  const unique = _.filter(
    justEmails,
    (el, idx) => justEmails.indexOf(el) === idx,
  ); // since can be multiple invites

  return unique;
}

function semanticList(list) {
  if (list.length === 2) {
    return list.join(' and ').toLowerCase();
  }
  if (list.length > 2) {
    return semanticList([list.slice(0, -1).join(', '), list[list.length - 1]]);
  }
  return list.join('').toLowerCase();
}

module.exports = {
  possessive,
  extractOthersList,
  semanticList,
};

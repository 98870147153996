/* eslint no-param-reassign: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'slate-react';
import data from 'emoji-mart/data/apple.json';
import styled from '@emotion/styled';
import { NimbleEmojiIndex } from 'emoji-mart';
import { Portal } from 'react-portal';
import RenderEditor from '../helpers/RenderEditor';
import position from '../../../../utils/caret-position';

const DOWN_ARROW_KEY = 40;
const UP_ARROW_KEY = 38;
const ENTER_KEY = 13;

const emojiIndex = new NimbleEmojiIndex(data);

const StyledLi = styled('li')`
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: white;
  background: ${props => (props.selected ? '#54acdc' : '#222')};
  cursor: pointer;

  span {
    padding: 0px 4px;
  }
`;

const SuggestionItem = ({
  closePanel,
  emoji,
  selectedIndex,
  index,
  setSelectedIndex,
  onSelect,
}) => {
  const onMouseEnter = () => {
    setSelectedIndex(index);
  };

  return (
    <StyledLi
      selected={index === selectedIndex}
      onMouseDown={e => {
        e.preventDefault();

        closePanel();
        onSelect();
      }}
      onMouseEnter={onMouseEnter}
    >
      <span>{emoji.native}</span>
      <span>{emoji.colons}</span>
    </StyledLi>
  );
};

SuggestionItem.propTypes = {
  closePanel: PropTypes.func,
  emoji: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    colons: PropTypes.string,
    text: PropTypes.string,
    emoticons: PropTypes.arrayOf(PropTypes.string),
    skin: PropTypes.number,
    native: PropTypes.string,
  }),
  selectedIndex: PropTypes.number,
  index: PropTypes.number,
  setSelectedIndex: PropTypes.func,
  onSelect: PropTypes.func,
};

const CAPTURE_REGEX = /:(\S*)$/;

const StyledPanel = styled('div')`
  padding: 8px 0px 6px;
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  opacity: 0;
  background-color: #222;
  border-radius: 4px;
  font-family: 'Lato', san-sans-serif;
  font-size: 16px;
  max-height: 384px;
  max-width: 192px;
  overflow-y: scroll;
  transition: opacity 0.75s;
`;

const SuggestionPanel = React.forwardRef(({ editor, callbacks }, ref) => {
  const [suggestions, setSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { value } = editor;

  const getEmojiColonInput = editorValue => {
    if (!editorValue.startText) {
      return null;
    }

    const startOffset = editorValue.selection.start.offset;
    const textBefore = editorValue.startText.text.slice(0, startOffset);
    const result = CAPTURE_REGEX.exec(textBefore);

    return result === null ? null : result[1];
  };

  const getSuggestions = searchString => {
    const filtered = emojiIndex.search(searchString);
    if (!filtered) return [];

    return filtered;
  };

  // add check that nearest node is parent and not link
  const hasValidAncestors = editorValue => {
    const { document, selection } = editorValue;
    return document.getParent(selection.start.key).type === 'paragraph';
  };

  useEffect(() => {
    const menu = ref.current;
    if (!menu) return;

    const match = getEmojiColonInput(value);
    if (!match || !hasValidAncestors(value)) {
      menu.removeAttribute('style');
      setSuggestions([]);
      return;
    }

    if (match) {
      setSuggestions(getSuggestions(match));
      const rect = position();
      menu.style.opacity = 1;
      menu.style.top = `${rect.top + window.scrollY + 18}px`;
      menu.style.left = `${rect.left + window.scrollX}px`;
    }
  }, [ref, value]);

  const onKeyDown = keyCode => {
    if (keyCode === DOWN_ARROW_KEY) {
      let current = selectedIndex;
      if (current + 1 === suggestions.length) {
        current = -1;
      }
      current += 1;
      setSelectedIndex(current);
    } else if (keyCode === UP_ARROW_KEY) {
      let current = selectedIndex;
      if (current === 0) {
        current = suggestions.length;
      }
      current -= 1;
      setSelectedIndex(current);
    } else {
      setSelectedIndex(0);
    }
  };

  const closePanel = () => {
    const menu = ref.current;
    if (!menu) return;

    menu.removeAttribute('style');
  };

  const onSelect = () => {
    const emoji = suggestions[selectedIndex];
    const abbrev = getEmojiColonInput(value);

    // delete the written version of the emoji and the :
    editor
      .deleteBackward(abbrev.length + 1)
      .insertText(emoji.native)
      .focus();
  };

  callbacks.onKeyDown = onKeyDown;
  callbacks.closePanel = closePanel;
  callbacks.onSelect = onSelect;

  return (
    <Portal>
      <StyledPanel className="suggestion-panel" ref={ref} tabIndex={-1}>
        <ul>
          {suggestions.map((suggestion, index) => (
            <SuggestionItem
              key={suggestion.id}
              index={index}
              emoji={suggestion}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              closePanel={closePanel}
              onSelect={onSelect}
            />
          ))}
        </ul>
      </StyledPanel>
    </Portal>
  );
});

SuggestionPanel.propTypes = {
  editor: PropTypes.instanceOf(Editor),
  callbacks: PropTypes.shape({
    closePanel: PropTypes.func,
    onSelect: PropTypes.func,
    onKeyDown: PropTypes.func,
  }),
};

const renderSuggestionPanel = (editor, args) => {
  const { ref, callbacks } = args;
  return <SuggestionPanel editor={editor} ref={ref} callbacks={callbacks} />;
};

function matchTrigger(value) {
  const currentNode = value.blocks.first();

  return value.selection.isFocused && CAPTURE_REGEX.test(currentNode.text);
}

function EmojiAutosuggestPlugin(options) {
  const { panelRef } = options;
  const callbacks = {};

  return [
    {
      onKeyDown(event, editor, next) {
        const { keyCode } = event;
        const { value } = editor;

        if (matchTrigger(value)) {
          // when portal is in view, prevent normal up/down
          if (keyCode === UP_ARROW_KEY || keyCode === DOWN_ARROW_KEY) {
            event.preventDefault();
          }

          // when portal in view, prevent normal enter/return
          if (keyCode === ENTER_KEY) {
            event.preventDefault();

            if (callbacks.closePanel) {
              callbacks.closePanel();
            }

            if (callbacks.onSelect) {
              return callbacks.onSelect();
            }
          } else if (callbacks.onKeyDown) {
            callbacks.onKeyDown(keyCode);
          }
        }

        return next();
      },
    },
    RenderEditor({
      renderFn: renderSuggestionPanel,
      args: { ref: panelRef, callbacks },
    }),
  ];
}

export default EmojiAutosuggestPlugin;

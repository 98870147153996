import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SvgLogoColor from './svgs/LogoColor';
import SvgLogoWhite from './svgs/LogoWhite';
import styles from '../styles/Logo.scss';

function Logo(props) {
  const { solid } = props;
  const src = solid ? (
    <SvgLogoWhite className={styles.logo} />
  ) : (
    <SvgLogoColor className={styles.logo} />
  );
  return <Link to="/">{src}</Link>;
}

Logo.propTypes = {
  solid: PropTypes.bool,
};

Logo.defaultProps = {
  solid: false,
};

export default Logo;

import React, {
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import _ from 'lodash';
import { useAuthContext } from './AuthProvider';

const noop = () => {};

const initialContext = { group: null, groups: [], selectGroup: noop };

const GroupContext = createContext(initialContext);

const soonestGroup = groups => {
  if (_.isEmpty(groups)) {
    return null;
  }

  const sorted = _.sortBy(groups, g => {
    const { current, subscription } = g;
    const due = current ? current.due : '3000';
    if (subscription.paused) {
      return null;
    }
    return due;
  });

  return sorted[0];
};

const GroupProvider = props => {
  const { me } = useAuthContext();
  const [group, setGroup] = useState(me ? soonestGroup(me.groups) : null);
  const [groups, setGroups] = useState(me ? me.groups || [] : []);

  // use when groups are set, either from me context or later if you call updateGroups
  const selectGroup = useCallback(
    groupId => {
      const selected = _.find(groups, ['id', groupId]);
      setGroup(selected);
    },
    [groups],
  );

  // assumes cycleId passed is a current of a group
  const selectGroupByCycleId = useCallback(
    cycleId => {
      const selected = _.find(groups, g => {
        if (!g.current) return false;
        return g.current.id === cycleId;
      });
      setGroup(selected);
    },
    [groups],
  );

  //
  const updateGroups = useCallback(newGroups => {
    setGroups(newGroups);
    setGroup(soonestGroup(newGroups));
  }, []);

  const contextValue = useMemo(
    () => ({
      group,
      groups,
      updateGroups,
      selectGroup,
      selectGroupByCycleId,
    }),
    [group, groups, selectGroup, selectGroupByCycleId, updateGroups],
  );

  return <GroupContext.Provider value={contextValue} {...props} />;
};

export const useGroupContext = () => useContext(GroupContext);

export default GroupProvider;

import posed from 'react-pose';

const Form = posed.div({
  enter: {
    opacity: 1,
    staggerChildren: 50,
  },
  exit: {
    opacity: 0,
    delay: 300,
    staggerChildren: 100,
    staggerDirection: -1,
  },
});

const FormSection = posed.div({
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
});

const ConfScreen = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
});

export { Form, FormSection, ConfScreen };
